import { Query, MassModification, EntryChange, ListChange, Modification, Condition, GroupCountQuery, AggregateQuery, GroupAggregateQuery, Aggregate, SortPart, DataClassPath, DataClassPathPartial, QueryPartial, apiCall, Path, DeepPartial } from '@lightningkite/lightning-server-simplified'


export interface Address {
    name: string
    street: string
    unitOrSuite: string
    city: string
    state: string
    country: string
    postal: string
}

export interface Attachment {
    name: string
    serverFile: ServerFile | null | undefined
    url: string | null | undefined
}

export interface Brand {
    _id: string
    name: string
    defaultTag: string | null | undefined
    siteType: BrandSiteType | null | undefined
    siteTypeExtraInfo: string | null | undefined
    limitToCollection: string | null | undefined
    lastSync: Instant
}

export enum BrandSiteType {
    klim = "klim",
    big_commerce = "big_commerce",
    big_commerce_sdr = "big_commerce_sdr",
    shopify = "shopify",
    shopify_d1 = "shopify_d1",
    shopify_mageworx = "shopify_mageworx",
    shopify_shopapps = "shopify_shopapps",
    shopify_ymq = "shopify_ymq",
    superatv = "superatv",
    tobeouterwear = "tobeouterwear",
}

export interface CartItem {
    product: ProductItem | null | undefined
    oem: OemItem | null | undefined
    custom: CustomItem | null | undefined
    quantity: number
}

export interface ChangeItem {
    _id: string
    deleted: boolean
    timestamp: Instant
}

export interface CoordinateRatio {
    x: number
    y: number
}

export interface CustomItem {
    name: string
    price: number
    shipping: number | null | undefined
}

export interface DynamicSettings {
    _id: string
    fitmentList: ServerFile | null | undefined
    fitmentListUtv: ServerFile | null | undefined
    fitmentListSnowmobile: ServerFile | null | undefined
    fitmentListWatercraft: ServerFile | null | undefined
    fitmentListMotorcycle: ServerFile | null | undefined
    imageResizers: number
    concurrentSyncers: number
    concurrentSyncersPerSite: number
    syncLimit: number | null | undefined
    syncPageSize: number
    oemSyncLimit: number | null | undefined
    autoSync: boolean
}

export interface EmailPinLogin {
    email: string
    pin: string
}

export interface ExternalAsyncTaskRequest {
    _id: string
    ourData: string
    expiresAt: Instant
    createdAt: Instant
    response: string | null | undefined
    result: string | null | undefined
    action: string | null | undefined
    lastAttempt: Instant
    processingError: string | null | undefined
}

export interface Fitment {
    sku: string
    year: number | null | undefined
    make: string | null | undefined
    model: string | null | undefined
    subModel: string | null | undefined
    universal: boolean
}

export interface FitmentYearRange {
    sku: string
    yearFrom: number | null | undefined
    yearTo: number | null | undefined
    make: string | null | undefined
    model: string | null | undefined
    subModel: string | null | undefined
    universal: boolean
}

export interface Giveaway {
    _id: UUID
    at: Instant
    image: SizedImage | null | undefined
    title: string
    description: string
    video: string | null | undefined
}

export interface HealthStatus {
    level: Level
    checkedAt: Instant
    additionalMessage: string | null | undefined
}

export interface HotSpot {
    tag: string
    topLeft: CoordinateRatio
    bottomRight: CoordinateRatio
}

type Instant = string  // java.time.Instant

export interface KnownModel {
    _id: string
    aliases: Array<string>
    minYear: number
    maxYear: number | null | undefined
    hasAnyProducts: boolean
    type: KnownModelType
}

export enum KnownModelType {
    UTV = "UTV",
    Snowmobile = "Snowmobile",
    Watercraft = "Watercraft",
    Motorcycle = "Motorcycle",
}

export interface LegacyOrderRequest {
    items: Array<CartItem>
    successUrl: string
    cancelUrl: string
    currency: string
    language: string
}

export interface LegacyPaymentResponse {
    _id: string
    url: string
}

export enum Level {
    OK = "OK",
    WARNING = "WARNING",
    URGENT = "URGENT",
    ERROR = "ERROR",
}

type LocalDate = string  // java.time.LocalDate

export interface Memory {
    max: number
    total: number
    free: number
    systemAllocated: number
    usage: number
}

export interface OEMAssembly {
    _id: string
    siteHash: string
    siteId: number
    name: string
    image: SizedImage
    hotSpots: Array<HotSpot>
    parts: Array<OEMPart>
    needsImagePass: boolean
    model: string
}

export interface OEMMake {
    _id: string
    name: string
    includedVehicles: Array<number> | null | undefined
    excludedVehicles: Array<number> | null | undefined
}

export interface OEMModel {
    _id: string
    name: string
    siteId: number
    year: UUID
    isParent: boolean
    parentModel: string | null | undefined
}

export interface OEMPart {
    id: number
    sku: string
    name: string
    msrp: Record<string, number>
    price: Record<string, number>
    tag: string | null | undefined
    extra: string
    quantity: number | null | undefined
    image: SizedImage | null | undefined
    status: StockStatus
}

export interface OEMVehicle {
    _id: UUID
    name: string
    slug: string
    siteId: number
    make: string
}

export interface OEMYear {
    _id: UUID
    name: string
    slug: string
    siteId: number
    vehicle: UUID
}

export interface OemItem {
    assembly: string
    part: number
}

export interface OemLineItem {
    assembly: string
    assemblyDenormalized: OEMAssembly | null | undefined
    part: number
    quantity: number
    totalListPrice: number
    totalSalePrice: number
    totalTax: number
    totalPaid: number
    totalDiscounted: number
    shippingLink: string | null | undefined
}

export interface OptionDimension {
    _id: string
    name: Record<string, string>
    values: Array<OptionValue>
    calculated: boolean
}

export interface OptionValue {
    _id: string
    name: Record<string, string>
    additional: string | null | undefined
    fitment: Array<string>
    additivePrice: number
}

export interface Order {
    _id: UUID
    createdAt: Instant
    customer: UUID
    products: Array<ProductLineItem>
    oemParts: Array<OemLineItem>
    customItems: Array<CustomItem>
    extraLineItems: Array<OtherLineItem>
    shipTo: Address
    paymentId: string
    totalListPrice: number
    totalSalePrice: number
    totalTax: number
    totalPaid: number
    totalDiscounted: number
    discountCodesUsed: Array<string>
    fulfillmentStarted: Instant | null | undefined
    fulfilled: Instant | null | undefined
    cancellationRequested: string | null | undefined
    cancelled: Instant | null | undefined
    notes: string
}

export interface OtherLineItem {
    id: string
    label: Record<string, string>
    totalListPrice: number
    totalSalePrice: number
    totalDiscounted: number
    totalTax: number
    totalPaid: number
    taxCode: string | null | undefined
}

export interface PendingOrder {
    _id: UUID
    createdAt: Instant
    customerEmail: string
    products: Array<ProductLineItem>
    oemParts: Array<OemLineItem>
    extraLineItems: Array<OtherLineItem>
    shipTo: Address
    paymentId: string
    token: string
    totalListPrice: number
    totalSalePrice: number
    totalTax: number
    totalPaid: number
}

export interface PriceRange {
    low: number
    high: number
}

export interface Product {
    _id: string
    name: Record<string, string>
    shortDescription: Record<string, string>
    description: Record<string, string>
    images: Array<SizedImage>
    redirectTo: string | null | undefined
    state: ProductState
    createdAt: Instant
    lastUpdated: Instant
    averageRating: number | null | undefined
    popularityPoints: number
    brandName: string
    site: string
    siteId: string
    skus: Array<string>
    mpn: string | null | undefined
    listPriceRanges: Record<string, PriceRange>
    salePriceRanges: Record<string, PriceRange>
    shippingPrices: Record<string, number> | null | undefined
    variableShipment: boolean
    options: Array<OptionDimension>
    fitment: Array<string>
    lockFitment: boolean
    fitApproxPossibilities: number
    taxCode: string | null | undefined
    attachments: Array<Attachment>
    needsImagePass: boolean
    tags: Array<string>
}

export interface ProductItem {
    product: string
    variant: UUID
    options: Record<string, string>
}

export interface ProductLineItem {
    product: string
    productDenormalized: Product | null | undefined
    variant: UUID
    variantDenormalized: ProductVariant | null | undefined
    options: Record<string, string>
    quantity: number
    totalListPrice: number
    totalSalePrice: number
    totalTax: number
    totalPaid: number
    totalDiscounted: number
    shippingLink: string | null | undefined
}

export interface ProductMinimized {
    _id: string
    name: string
    description: string
    firstImage: SizedImage | null | undefined
    averageRating: number | null | undefined
    listPriceRanges: PriceRange
    salePriceRanges: PriceRange
    optionNames: Array<string>
}

export enum ProductState {
    Available = "Available",
    TemporarilyUnavailable = "TemporarilyUnavailable",
    Unavailable = "Unavailable",
    Unlisted = "Unlisted",
    Hidden = "Hidden",
    New = "New",
}

export interface ProductTag {
    _id: string
    name: string
    seoTitle: string
    seoDescription: string
    parentTag: string | null | undefined
}

export interface ProductVariant {
    _id: UUID
    sku: string | null | undefined
    product: string
    options: Record<string, string>
    listPrices: Record<string, number>
    salePrices: Record<string, number>
    images: Array<SizedImage>
    fitment: Array<string>
    available: boolean
    hidden: boolean
    lastUpdated: Instant
    weightPounds: number
    widthInches: number
    heightInches: number
    lengthInches: number
    needsImagePass: boolean
}

export interface Review {
    _id: UUID
    product: string
    author: UUID
    content: string
    rating: number
}

export interface Sale {
    _id: string
    startsAt: Instant
    endsAt: Instant
    name: Record<string, string>
    image: SizedImage | null | undefined
    targets: Array<SaleTarget>
    percentOff: number
}

export interface SaleTarget {
    brand: string | null | undefined
    tag: string | null | undefined
    product: string | null | undefined
    variant: UUID | null | undefined
    setPrices: Record<string, number> | null | undefined
}

/**
* A URL referencing a file that the server owns.
**/
type ServerFile = string  // com.lightningkite.lightningdb.ServerFile

export interface ServerHealth {
    serverId: string
    version: string
    memory: Memory
    features: Record<string, HealthStatus>
    loadAverageCpu: number
}

export interface ShopTalk {
    _id: UUID
    productRefs: Array<string>
    title: string
    description: string
    seoDescription: string
    youtubeEmbedLink: string
    transcript: string
    published: boolean
    date: LocalDate
}

export interface SizedImage {
    name: string
    base: ServerFile | null | undefined
    jpg: Record<string, ServerFile>
    webp: Record<string, ServerFile>
    source: string | null | undefined
}

export interface StartOrderRequest {
    customerEmail: string | null | undefined
    items: Array<CartItem>
    shipping: Address
    currency: string
    language: string
}

export enum StockStatus {
    IN_STOCK = "IN_STOCK",
    OUT_OF_STOCK = "OUT_OF_STOCK",
}

export interface SyncProgress {
    _id: string
    createdAt: Instant
    lastUpdate: Instant
    stepsDone: number
    totalSteps: number | null | undefined
    activeConcurrency: number
    exceptions: Array<string>
}

export interface SyncRequest {
    brand: string
    concurrency: number | null | undefined
    limit: number | null | undefined
    pageSize: number | null | undefined
}

export interface SyncRequest {
    limit: number | null | undefined
}

export interface SyncRequest {
    concurrency: number | null | undefined
    tag: number | null | undefined
}

type UUID = string  // java.util.UUID

export interface UploadInformation {
    uploadUrl: string
    futureCallToken: string
}

export interface User {
    _id: UUID
    email: string
    isAdmin: boolean
    cart: Array<CartItem>
    points: number
    discountCodesUsed: Array<string>
}



export interface Api {
    
     /**
     * Begin Sync
     **/
    beginSync(input: SyncRequest, userToken: string): Promise<SyncProgress | null | undefined>
    
     /**
     * Begin Sync Temecula
     **/
    beginSyncTemecula(input: SyncRequest, userToken: string): Promise<void>
    
     /**
     * Returns the pending order tied to the Stripe Payment Intent Client Secret
     **/
    getPendingOrder(clientSecret: string, userToken?: string): Promise<PendingOrder>
    
     /**
     * Gets the current status of the server
     **/
    getServerHealth(userToken: string): Promise<ServerHealth>
    
     /**
     * Get Version
     **/
    getVersion(): Promise<string>
    
     /**
     * Returns a url for a csv with all product data int the Google Merchant Listing format.
     **/
    merchantListings(userToken: string): Promise<string>
    
     /**
     * Pick a random user for a giveaway
     **/
    pickARandomUserForAGiveaway(userToken: string): Promise<User>
    
     /**
     * Place Order
     **/
    placeOrder(input: LegacyOrderRequest, userToken?: string): Promise<LegacyPaymentResponse>
    
     /**
     * Place Order v2
     **/
    placeOrderV2(input: StartOrderRequest, userToken?: string): Promise<string>
    
     /**
     * Adds the fitment provided to the relevant products.
     **/
    submitFitment(brandId: string, input: Array<Fitment>, userToken: string): Promise<void>
    
     /**
     * Adds the fitment provided to the relevant products.
     **/
    submitFitmentYearRange(brandId: string, input: Array<FitmentYearRange>, userToken: string): Promise<void>
    
     /**
     * Brings over all the categories from the old woocommerce site
     **/
    syncWoocommerceCategories(userToken: string): Promise<void>
    
     /**
     * Brings over all the categories for each product from the old woocommerce site
     **/
    syncWoocommerceProductCategories(input: SyncRequest, userToken: string): Promise<SyncProgress>
    
     /**
     * Upload a file to make a request later.  Times out in around 10 minutes.
     **/
    uploadFileForRequest(): Promise<UploadInformation>
    readonly auth: {
        
        /**
        * Creates a new token for the user, which can be used to authenticate with the API via the header 'Authorization: Bearer [insert token here]'.
        **/
        refreshToken(userToken: string): Promise<string>
        
        /**
        * Retrieves the user that you currently authenticated as.
        **/
        getSelf(userToken: string): Promise<User>
        
        /**
        * Creates a token for a new, anonymous user.  The token can be used to authenticate with the API via the header 'Authorization: Bearer [insert token here]
        **/
        anonymousToken(userToken?: string): Promise<string>
        
        /**
        * Sends a login email to the given address.  The email will contain both a link to instantly log in and a PIN that can be entered to log in.
        **/
        emailLoginLink(input: string): Promise<void>
        
        /**
        * Logs in to the given account with a PIN that was provided in an email sent earlier.  Note that the PIN expires in 15 minutes, and you are only permitted 5 attempts.
        **/
        emailPINLogin(input: EmailPinLogin): Promise<string>
    }
    readonly brand: {
        
        /**
        * Gets a default Brand that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<Brand>
        
        /**
        * Gets a list of Brands that match the given query.
        **/
        query(input: Query<Brand>, userToken?: string): Promise<Array<Brand>>
        
        /**
        * Gets parts of Brands that match the given query.
        **/
        queryPartial(input: QueryPartial<Brand>, userToken?: string): Promise<Array<DeepPartial<Brand>>>
        
        /**
        * Gets a single Brand by ID.
        **/
        detail(id: string, userToken?: string): Promise<Brand>
        
        /**
        * Creates multiple Brands at the same time.
        **/
        insertBulk(input: Array<Brand>, userToken?: string): Promise<Array<Brand>>
        
        /**
        * Creates a new Brand
        **/
        insert(input: Brand, userToken?: string): Promise<Brand>
        
        /**
        * Creates or updates a Brand
        **/
        upsert(id: string, input: Brand, userToken?: string): Promise<Brand>
        
        /**
        * Modifies many Brands at the same time by ID.
        **/
        bulkReplace(input: Array<Brand>, userToken?: string): Promise<Array<Brand>>
        
        /**
        * Replaces a single Brand by ID.
        **/
        replace(id: string, input: Brand, userToken?: string): Promise<Brand>
        
        /**
        * Modifies many Brands at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<Brand>, userToken?: string): Promise<number>
        
        /**
        * Modifies a Brand by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<Brand>, userToken?: string): Promise<EntryChange<Brand>>
        
        /**
        * Modifies a Brand by ID, returning the new value.
        **/
        modify(id: string, input: Modification<Brand>, userToken?: string): Promise<Brand>
        
        /**
        * Deletes all matching Brands, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<Brand>, userToken?: string): Promise<number>
        
        /**
        * Deletes a Brand by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of Brands matching the given condition.
        **/
        count(input: Condition<Brand>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of Brands matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<Brand>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Brands matching the given condition.
        **/
        aggregate(input: AggregateQuery<Brand>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Brands matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<Brand>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly changeItem: {
        
        /**
        * Gets a default ChangeItem that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<ChangeItem>
        
        /**
        * Gets a list of ChangeItems that match the given query.
        **/
        query(input: Query<ChangeItem>, userToken?: string): Promise<Array<ChangeItem>>
        
        /**
        * Gets parts of ChangeItems that match the given query.
        **/
        queryPartial(input: QueryPartial<ChangeItem>, userToken?: string): Promise<Array<DeepPartial<ChangeItem>>>
        
        /**
        * Gets a single ChangeItem by ID.
        **/
        detail(id: string, userToken?: string): Promise<ChangeItem>
        
        /**
        * Creates multiple ChangeItems at the same time.
        **/
        insertBulk(input: Array<ChangeItem>, userToken?: string): Promise<Array<ChangeItem>>
        
        /**
        * Creates a new ChangeItem
        **/
        insert(input: ChangeItem, userToken?: string): Promise<ChangeItem>
        
        /**
        * Creates or updates a ChangeItem
        **/
        upsert(id: string, input: ChangeItem, userToken?: string): Promise<ChangeItem>
        
        /**
        * Modifies many ChangeItems at the same time by ID.
        **/
        bulkReplace(input: Array<ChangeItem>, userToken?: string): Promise<Array<ChangeItem>>
        
        /**
        * Replaces a single ChangeItem by ID.
        **/
        replace(id: string, input: ChangeItem, userToken?: string): Promise<ChangeItem>
        
        /**
        * Modifies many ChangeItems at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<ChangeItem>, userToken?: string): Promise<number>
        
        /**
        * Modifies a ChangeItem by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<ChangeItem>, userToken?: string): Promise<EntryChange<ChangeItem>>
        
        /**
        * Modifies a ChangeItem by ID, returning the new value.
        **/
        modify(id: string, input: Modification<ChangeItem>, userToken?: string): Promise<ChangeItem>
        
        /**
        * Deletes all matching ChangeItems, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<ChangeItem>, userToken?: string): Promise<number>
        
        /**
        * Deletes a ChangeItem by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of ChangeItems matching the given condition.
        **/
        count(input: Condition<ChangeItem>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of ChangeItems matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<ChangeItem>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of ChangeItems matching the given condition.
        **/
        aggregate(input: AggregateQuery<ChangeItem>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of ChangeItems matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<ChangeItem>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly dynamicSettings: {
        
        /**
        * Gets a default DynamicSettings that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken: string): Promise<DynamicSettings>
        
        /**
        * Gets a list of DynamicSettingss that match the given query.
        **/
        query(input: Query<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>>
        
        /**
        * Gets parts of DynamicSettingss that match the given query.
        **/
        queryPartial(input: QueryPartial<DynamicSettings>, userToken: string): Promise<Array<DeepPartial<DynamicSettings>>>
        
        /**
        * Gets a single DynamicSettings by ID.
        **/
        detail(id: string, userToken: string): Promise<DynamicSettings>
        
        /**
        * Creates multiple DynamicSettingss at the same time.
        **/
        insertBulk(input: Array<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>>
        
        /**
        * Creates a new DynamicSettings
        **/
        insert(input: DynamicSettings, userToken: string): Promise<DynamicSettings>
        
        /**
        * Creates or updates a DynamicSettings
        **/
        upsert(id: string, input: DynamicSettings, userToken: string): Promise<DynamicSettings>
        
        /**
        * Modifies many DynamicSettingss at the same time by ID.
        **/
        bulkReplace(input: Array<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>>
        
        /**
        * Replaces a single DynamicSettings by ID.
        **/
        replace(id: string, input: DynamicSettings, userToken: string): Promise<DynamicSettings>
        
        /**
        * Modifies many DynamicSettingss at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<DynamicSettings>, userToken: string): Promise<number>
        
        /**
        * Modifies a DynamicSettings by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<DynamicSettings>, userToken: string): Promise<EntryChange<DynamicSettings>>
        
        /**
        * Modifies a DynamicSettings by ID, returning the new value.
        **/
        modify(id: string, input: Modification<DynamicSettings>, userToken: string): Promise<DynamicSettings>
        
        /**
        * Deletes all matching DynamicSettingss, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<DynamicSettings>, userToken: string): Promise<number>
        
        /**
        * Deletes a DynamicSettings by id.
        **/
        delete(id: string, userToken: string): Promise<void>
        
        /**
        * Gets the total number of DynamicSettingss matching the given condition.
        **/
        count(input: Condition<DynamicSettings>, userToken: string): Promise<number>
        
        /**
        * Gets the total number of DynamicSettingss matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<DynamicSettings>, userToken: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of DynamicSettingss matching the given condition.
        **/
        aggregate(input: AggregateQuery<DynamicSettings>, userToken: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of DynamicSettingss matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<DynamicSettings>, userToken: string): Promise<Record<string, number | null | undefined>>
    }
    readonly giveaway: {
        
        /**
        * Gets a default Giveaway that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<Giveaway>
        
        /**
        * Gets a list of Giveaways that match the given query.
        **/
        query(input: Query<Giveaway>, userToken?: string): Promise<Array<Giveaway>>
        
        /**
        * Gets parts of Giveaways that match the given query.
        **/
        queryPartial(input: QueryPartial<Giveaway>, userToken?: string): Promise<Array<DeepPartial<Giveaway>>>
        
        /**
        * Gets a single Giveaway by ID.
        **/
        detail(id: UUID, userToken?: string): Promise<Giveaway>
        
        /**
        * Creates multiple Giveaways at the same time.
        **/
        insertBulk(input: Array<Giveaway>, userToken?: string): Promise<Array<Giveaway>>
        
        /**
        * Creates a new Giveaway
        **/
        insert(input: Giveaway, userToken?: string): Promise<Giveaway>
        
        /**
        * Creates or updates a Giveaway
        **/
        upsert(id: UUID, input: Giveaway, userToken?: string): Promise<Giveaway>
        
        /**
        * Modifies many Giveaways at the same time by ID.
        **/
        bulkReplace(input: Array<Giveaway>, userToken?: string): Promise<Array<Giveaway>>
        
        /**
        * Replaces a single Giveaway by ID.
        **/
        replace(id: UUID, input: Giveaway, userToken?: string): Promise<Giveaway>
        
        /**
        * Modifies many Giveaways at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<Giveaway>, userToken?: string): Promise<number>
        
        /**
        * Modifies a Giveaway by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<Giveaway>, userToken?: string): Promise<EntryChange<Giveaway>>
        
        /**
        * Modifies a Giveaway by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<Giveaway>, userToken?: string): Promise<Giveaway>
        
        /**
        * Deletes all matching Giveaways, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<Giveaway>, userToken?: string): Promise<number>
        
        /**
        * Deletes a Giveaway by id.
        **/
        delete(id: UUID, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of Giveaways matching the given condition.
        **/
        count(input: Condition<Giveaway>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of Giveaways matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<Giveaway>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Giveaways matching the given condition.
        **/
        aggregate(input: AggregateQuery<Giveaway>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Giveaways matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<Giveaway>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly knownModel: {
        
        /**
        * Gets a default KnownModel that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<KnownModel>
        
        /**
        * Gets a list of KnownModels that match the given query.
        **/
        query(input: Query<KnownModel>, userToken?: string): Promise<Array<KnownModel>>
        
        /**
        * Gets parts of KnownModels that match the given query.
        **/
        queryPartial(input: QueryPartial<KnownModel>, userToken?: string): Promise<Array<DeepPartial<KnownModel>>>
        
        /**
        * Gets a single KnownModel by ID.
        **/
        detail(id: string, userToken?: string): Promise<KnownModel>
        
        /**
        * Creates multiple KnownModels at the same time.
        **/
        insertBulk(input: Array<KnownModel>, userToken?: string): Promise<Array<KnownModel>>
        
        /**
        * Creates a new KnownModel
        **/
        insert(input: KnownModel, userToken?: string): Promise<KnownModel>
        
        /**
        * Creates or updates a KnownModel
        **/
        upsert(id: string, input: KnownModel, userToken?: string): Promise<KnownModel>
        
        /**
        * Modifies many KnownModels at the same time by ID.
        **/
        bulkReplace(input: Array<KnownModel>, userToken?: string): Promise<Array<KnownModel>>
        
        /**
        * Replaces a single KnownModel by ID.
        **/
        replace(id: string, input: KnownModel, userToken?: string): Promise<KnownModel>
        
        /**
        * Modifies many KnownModels at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<KnownModel>, userToken?: string): Promise<number>
        
        /**
        * Modifies a KnownModel by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<KnownModel>, userToken?: string): Promise<EntryChange<KnownModel>>
        
        /**
        * Modifies a KnownModel by ID, returning the new value.
        **/
        modify(id: string, input: Modification<KnownModel>, userToken?: string): Promise<KnownModel>
        
        /**
        * Deletes all matching KnownModels, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<KnownModel>, userToken?: string): Promise<number>
        
        /**
        * Deletes a KnownModel by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of KnownModels matching the given condition.
        **/
        count(input: Condition<KnownModel>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of KnownModels matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<KnownModel>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of KnownModels matching the given condition.
        **/
        aggregate(input: AggregateQuery<KnownModel>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of KnownModels matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<KnownModel>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly legacyPayment: {
        
        /**
        * Gets a default Legacy-paymentRequest that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Gets a list of Legacy-paymentRequests that match the given query.
        **/
        query(input: Query<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>>
        
        /**
        * Gets parts of Legacy-paymentRequests that match the given query.
        **/
        queryPartial(input: QueryPartial<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<DeepPartial<ExternalAsyncTaskRequest>>>
        
        /**
        * Gets a single Legacy-paymentRequest by ID.
        **/
        detail(id: string, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Creates multiple Legacy-paymentRequests at the same time.
        **/
        insertBulk(input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>>
        
        /**
        * Creates a new Legacy-paymentRequest
        **/
        insert(input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Creates or updates a Legacy-paymentRequest
        **/
        upsert(id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Modifies many Legacy-paymentRequests at the same time by ID.
        **/
        bulkReplace(input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>>
        
        /**
        * Replaces a single Legacy-paymentRequest by ID.
        **/
        replace(id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Modifies many Legacy-paymentRequests at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<ExternalAsyncTaskRequest>, userToken: string): Promise<number>
        
        /**
        * Modifies a Legacy-paymentRequest by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<EntryChange<ExternalAsyncTaskRequest>>
        
        /**
        * Modifies a Legacy-paymentRequest by ID, returning the new value.
        **/
        modify(id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Deletes all matching Legacy-paymentRequests, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number>
        
        /**
        * Deletes a Legacy-paymentRequest by id.
        **/
        delete(id: string, userToken: string): Promise<void>
        
        /**
        * Gets the total number of Legacy-paymentRequests matching the given condition.
        **/
        count(input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number>
        
        /**
        * Gets the total number of Legacy-paymentRequests matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Legacy-paymentRequests matching the given condition.
        **/
        aggregate(input: AggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Legacy-paymentRequests matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number | null | undefined>>
        
        /**
        * Manually recheck tasks
        **/
        manuallyRecheckTasks(userToken: string): Promise<void>
        
        /**
        * Manually recheck a task
        **/
        manuallyRecheckATask(id: string, userToken: string): Promise<void>
    }
    readonly oEMAssembly: {
        
        /**
        * Gets a default OEMAssembly that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<OEMAssembly>
        
        /**
        * Gets a list of OEMAssemblys that match the given query.
        **/
        query(input: Query<OEMAssembly>, userToken?: string): Promise<Array<OEMAssembly>>
        
        /**
        * Gets parts of OEMAssemblys that match the given query.
        **/
        queryPartial(input: QueryPartial<OEMAssembly>, userToken?: string): Promise<Array<DeepPartial<OEMAssembly>>>
        
        /**
        * Gets a single OEMAssembly by ID.
        **/
        detail(id: string, userToken?: string): Promise<OEMAssembly>
        
        /**
        * Creates multiple OEMAssemblys at the same time.
        **/
        insertBulk(input: Array<OEMAssembly>, userToken?: string): Promise<Array<OEMAssembly>>
        
        /**
        * Creates a new OEMAssembly
        **/
        insert(input: OEMAssembly, userToken?: string): Promise<OEMAssembly>
        
        /**
        * Creates or updates a OEMAssembly
        **/
        upsert(id: string, input: OEMAssembly, userToken?: string): Promise<OEMAssembly>
        
        /**
        * Modifies many OEMAssemblys at the same time by ID.
        **/
        bulkReplace(input: Array<OEMAssembly>, userToken?: string): Promise<Array<OEMAssembly>>
        
        /**
        * Replaces a single OEMAssembly by ID.
        **/
        replace(id: string, input: OEMAssembly, userToken?: string): Promise<OEMAssembly>
        
        /**
        * Modifies many OEMAssemblys at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<OEMAssembly>, userToken?: string): Promise<number>
        
        /**
        * Modifies a OEMAssembly by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<OEMAssembly>, userToken?: string): Promise<EntryChange<OEMAssembly>>
        
        /**
        * Modifies a OEMAssembly by ID, returning the new value.
        **/
        modify(id: string, input: Modification<OEMAssembly>, userToken?: string): Promise<OEMAssembly>
        
        /**
        * Deletes all matching OEMAssemblys, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<OEMAssembly>, userToken?: string): Promise<number>
        
        /**
        * Deletes a OEMAssembly by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of OEMAssemblys matching the given condition.
        **/
        count(input: Condition<OEMAssembly>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of OEMAssemblys matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<OEMAssembly>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of OEMAssemblys matching the given condition.
        **/
        aggregate(input: AggregateQuery<OEMAssembly>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of OEMAssemblys matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<OEMAssembly>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly oEMMake: {
        
        /**
        * Gets a default OEMMake that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<OEMMake>
        
        /**
        * Gets a list of OEMMakes that match the given query.
        **/
        query(input: Query<OEMMake>, userToken?: string): Promise<Array<OEMMake>>
        
        /**
        * Gets parts of OEMMakes that match the given query.
        **/
        queryPartial(input: QueryPartial<OEMMake>, userToken?: string): Promise<Array<DeepPartial<OEMMake>>>
        
        /**
        * Gets a single OEMMake by ID.
        **/
        detail(id: string, userToken?: string): Promise<OEMMake>
        
        /**
        * Creates multiple OEMMakes at the same time.
        **/
        insertBulk(input: Array<OEMMake>, userToken?: string): Promise<Array<OEMMake>>
        
        /**
        * Creates a new OEMMake
        **/
        insert(input: OEMMake, userToken?: string): Promise<OEMMake>
        
        /**
        * Creates or updates a OEMMake
        **/
        upsert(id: string, input: OEMMake, userToken?: string): Promise<OEMMake>
        
        /**
        * Modifies many OEMMakes at the same time by ID.
        **/
        bulkReplace(input: Array<OEMMake>, userToken?: string): Promise<Array<OEMMake>>
        
        /**
        * Replaces a single OEMMake by ID.
        **/
        replace(id: string, input: OEMMake, userToken?: string): Promise<OEMMake>
        
        /**
        * Modifies many OEMMakes at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<OEMMake>, userToken?: string): Promise<number>
        
        /**
        * Modifies a OEMMake by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<OEMMake>, userToken?: string): Promise<EntryChange<OEMMake>>
        
        /**
        * Modifies a OEMMake by ID, returning the new value.
        **/
        modify(id: string, input: Modification<OEMMake>, userToken?: string): Promise<OEMMake>
        
        /**
        * Deletes all matching OEMMakes, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<OEMMake>, userToken?: string): Promise<number>
        
        /**
        * Deletes a OEMMake by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of OEMMakes matching the given condition.
        **/
        count(input: Condition<OEMMake>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of OEMMakes matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<OEMMake>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of OEMMakes matching the given condition.
        **/
        aggregate(input: AggregateQuery<OEMMake>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of OEMMakes matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<OEMMake>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly oEMModel: {
        
        /**
        * Gets a default OEMModel that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<OEMModel>
        
        /**
        * Gets a list of OEMModels that match the given query.
        **/
        query(input: Query<OEMModel>, userToken?: string): Promise<Array<OEMModel>>
        
        /**
        * Gets parts of OEMModels that match the given query.
        **/
        queryPartial(input: QueryPartial<OEMModel>, userToken?: string): Promise<Array<DeepPartial<OEMModel>>>
        
        /**
        * Gets a single OEMModel by ID.
        **/
        detail(id: string, userToken?: string): Promise<OEMModel>
        
        /**
        * Creates multiple OEMModels at the same time.
        **/
        insertBulk(input: Array<OEMModel>, userToken?: string): Promise<Array<OEMModel>>
        
        /**
        * Creates a new OEMModel
        **/
        insert(input: OEMModel, userToken?: string): Promise<OEMModel>
        
        /**
        * Creates or updates a OEMModel
        **/
        upsert(id: string, input: OEMModel, userToken?: string): Promise<OEMModel>
        
        /**
        * Modifies many OEMModels at the same time by ID.
        **/
        bulkReplace(input: Array<OEMModel>, userToken?: string): Promise<Array<OEMModel>>
        
        /**
        * Replaces a single OEMModel by ID.
        **/
        replace(id: string, input: OEMModel, userToken?: string): Promise<OEMModel>
        
        /**
        * Modifies many OEMModels at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<OEMModel>, userToken?: string): Promise<number>
        
        /**
        * Modifies a OEMModel by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<OEMModel>, userToken?: string): Promise<EntryChange<OEMModel>>
        
        /**
        * Modifies a OEMModel by ID, returning the new value.
        **/
        modify(id: string, input: Modification<OEMModel>, userToken?: string): Promise<OEMModel>
        
        /**
        * Deletes all matching OEMModels, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<OEMModel>, userToken?: string): Promise<number>
        
        /**
        * Deletes a OEMModel by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of OEMModels matching the given condition.
        **/
        count(input: Condition<OEMModel>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of OEMModels matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<OEMModel>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of OEMModels matching the given condition.
        **/
        aggregate(input: AggregateQuery<OEMModel>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of OEMModels matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<OEMModel>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly oEMVehicle: {
        
        /**
        * Gets a default OEMVehicle that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<OEMVehicle>
        
        /**
        * Gets a list of OEMVehicles that match the given query.
        **/
        query(input: Query<OEMVehicle>, userToken?: string): Promise<Array<OEMVehicle>>
        
        /**
        * Gets parts of OEMVehicles that match the given query.
        **/
        queryPartial(input: QueryPartial<OEMVehicle>, userToken?: string): Promise<Array<DeepPartial<OEMVehicle>>>
        
        /**
        * Gets a single OEMVehicle by ID.
        **/
        detail(id: UUID, userToken?: string): Promise<OEMVehicle>
        
        /**
        * Creates multiple OEMVehicles at the same time.
        **/
        insertBulk(input: Array<OEMVehicle>, userToken?: string): Promise<Array<OEMVehicle>>
        
        /**
        * Creates a new OEMVehicle
        **/
        insert(input: OEMVehicle, userToken?: string): Promise<OEMVehicle>
        
        /**
        * Creates or updates a OEMVehicle
        **/
        upsert(id: UUID, input: OEMVehicle, userToken?: string): Promise<OEMVehicle>
        
        /**
        * Modifies many OEMVehicles at the same time by ID.
        **/
        bulkReplace(input: Array<OEMVehicle>, userToken?: string): Promise<Array<OEMVehicle>>
        
        /**
        * Replaces a single OEMVehicle by ID.
        **/
        replace(id: UUID, input: OEMVehicle, userToken?: string): Promise<OEMVehicle>
        
        /**
        * Modifies many OEMVehicles at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<OEMVehicle>, userToken?: string): Promise<number>
        
        /**
        * Modifies a OEMVehicle by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<OEMVehicle>, userToken?: string): Promise<EntryChange<OEMVehicle>>
        
        /**
        * Modifies a OEMVehicle by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<OEMVehicle>, userToken?: string): Promise<OEMVehicle>
        
        /**
        * Deletes all matching OEMVehicles, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<OEMVehicle>, userToken?: string): Promise<number>
        
        /**
        * Deletes a OEMVehicle by id.
        **/
        delete(id: UUID, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of OEMVehicles matching the given condition.
        **/
        count(input: Condition<OEMVehicle>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of OEMVehicles matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<OEMVehicle>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of OEMVehicles matching the given condition.
        **/
        aggregate(input: AggregateQuery<OEMVehicle>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of OEMVehicles matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<OEMVehicle>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly oEMYear: {
        
        /**
        * Gets a default OEMYear that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<OEMYear>
        
        /**
        * Gets a list of OEMYears that match the given query.
        **/
        query(input: Query<OEMYear>, userToken?: string): Promise<Array<OEMYear>>
        
        /**
        * Gets parts of OEMYears that match the given query.
        **/
        queryPartial(input: QueryPartial<OEMYear>, userToken?: string): Promise<Array<DeepPartial<OEMYear>>>
        
        /**
        * Gets a single OEMYear by ID.
        **/
        detail(id: UUID, userToken?: string): Promise<OEMYear>
        
        /**
        * Creates multiple OEMYears at the same time.
        **/
        insertBulk(input: Array<OEMYear>, userToken?: string): Promise<Array<OEMYear>>
        
        /**
        * Creates a new OEMYear
        **/
        insert(input: OEMYear, userToken?: string): Promise<OEMYear>
        
        /**
        * Creates or updates a OEMYear
        **/
        upsert(id: UUID, input: OEMYear, userToken?: string): Promise<OEMYear>
        
        /**
        * Modifies many OEMYears at the same time by ID.
        **/
        bulkReplace(input: Array<OEMYear>, userToken?: string): Promise<Array<OEMYear>>
        
        /**
        * Replaces a single OEMYear by ID.
        **/
        replace(id: UUID, input: OEMYear, userToken?: string): Promise<OEMYear>
        
        /**
        * Modifies many OEMYears at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<OEMYear>, userToken?: string): Promise<number>
        
        /**
        * Modifies a OEMYear by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<OEMYear>, userToken?: string): Promise<EntryChange<OEMYear>>
        
        /**
        * Modifies a OEMYear by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<OEMYear>, userToken?: string): Promise<OEMYear>
        
        /**
        * Deletes all matching OEMYears, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<OEMYear>, userToken?: string): Promise<number>
        
        /**
        * Deletes a OEMYear by id.
        **/
        delete(id: UUID, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of OEMYears matching the given condition.
        **/
        count(input: Condition<OEMYear>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of OEMYears matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<OEMYear>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of OEMYears matching the given condition.
        **/
        aggregate(input: AggregateQuery<OEMYear>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of OEMYears matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<OEMYear>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly order: {
        
        /**
        * Gets a default Order that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<Order>
        
        /**
        * Gets a list of Orders that match the given query.
        **/
        query(input: Query<Order>, userToken?: string): Promise<Array<Order>>
        
        /**
        * Gets parts of Orders that match the given query.
        **/
        queryPartial(input: QueryPartial<Order>, userToken?: string): Promise<Array<DeepPartial<Order>>>
        
        /**
        * Gets a single Order by ID.
        **/
        detail(id: UUID, userToken?: string): Promise<Order>
        
        /**
        * Creates multiple Orders at the same time.
        **/
        insertBulk(input: Array<Order>, userToken?: string): Promise<Array<Order>>
        
        /**
        * Creates a new Order
        **/
        insert(input: Order, userToken?: string): Promise<Order>
        
        /**
        * Creates or updates a Order
        **/
        upsert(id: UUID, input: Order, userToken?: string): Promise<Order>
        
        /**
        * Modifies many Orders at the same time by ID.
        **/
        bulkReplace(input: Array<Order>, userToken?: string): Promise<Array<Order>>
        
        /**
        * Replaces a single Order by ID.
        **/
        replace(id: UUID, input: Order, userToken?: string): Promise<Order>
        
        /**
        * Modifies many Orders at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<Order>, userToken?: string): Promise<number>
        
        /**
        * Modifies a Order by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<Order>, userToken?: string): Promise<EntryChange<Order>>
        
        /**
        * Modifies a Order by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<Order>, userToken?: string): Promise<Order>
        
        /**
        * Deletes all matching Orders, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<Order>, userToken?: string): Promise<number>
        
        /**
        * Deletes a Order by id.
        **/
        delete(id: UUID, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of Orders matching the given condition.
        **/
        count(input: Condition<Order>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of Orders matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<Order>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Orders matching the given condition.
        **/
        aggregate(input: AggregateQuery<Order>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Orders matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<Order>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly payment: {
        
        /**
        * Gets a default PaymentRequest that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Gets a list of PaymentRequests that match the given query.
        **/
        query(input: Query<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>>
        
        /**
        * Gets parts of PaymentRequests that match the given query.
        **/
        queryPartial(input: QueryPartial<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<DeepPartial<ExternalAsyncTaskRequest>>>
        
        /**
        * Gets a single PaymentRequest by ID.
        **/
        detail(id: string, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Creates multiple PaymentRequests at the same time.
        **/
        insertBulk(input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>>
        
        /**
        * Creates a new PaymentRequest
        **/
        insert(input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Creates or updates a PaymentRequest
        **/
        upsert(id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Modifies many PaymentRequests at the same time by ID.
        **/
        bulkReplace(input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>>
        
        /**
        * Replaces a single PaymentRequest by ID.
        **/
        replace(id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Modifies many PaymentRequests at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<ExternalAsyncTaskRequest>, userToken: string): Promise<number>
        
        /**
        * Modifies a PaymentRequest by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<EntryChange<ExternalAsyncTaskRequest>>
        
        /**
        * Modifies a PaymentRequest by ID, returning the new value.
        **/
        modify(id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<ExternalAsyncTaskRequest>
        
        /**
        * Deletes all matching PaymentRequests, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number>
        
        /**
        * Deletes a PaymentRequest by id.
        **/
        delete(id: string, userToken: string): Promise<void>
        
        /**
        * Gets the total number of PaymentRequests matching the given condition.
        **/
        count(input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number>
        
        /**
        * Gets the total number of PaymentRequests matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of PaymentRequests matching the given condition.
        **/
        aggregate(input: AggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of PaymentRequests matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number | null | undefined>>
        
        /**
        * Manually recheck tasks
        **/
        manuallyRecheckTasks(userToken: string): Promise<void>
        
        /**
        * Manually recheck a task
        **/
        manuallyRecheckATask(id: string, userToken: string): Promise<void>
    }
    readonly product: {
        
        /**
        * Gets a default Product that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<Product>
        
        /**
        * Gets a list of Products that match the given query.
        **/
        query(input: Query<Product>, userToken?: string): Promise<Array<Product>>
        
        /**
        * Gets parts of Products that match the given query.
        **/
        queryPartial(input: QueryPartial<Product>, userToken?: string): Promise<Array<DeepPartial<Product>>>
        
        /**
        * Gets a single Product by ID.
        **/
        detail(id: string, userToken?: string): Promise<Product>
        
        /**
        * Creates multiple Products at the same time.
        **/
        insertBulk(input: Array<Product>, userToken?: string): Promise<Array<Product>>
        
        /**
        * Creates a new Product
        **/
        insert(input: Product, userToken?: string): Promise<Product>
        
        /**
        * Creates or updates a Product
        **/
        upsert(id: string, input: Product, userToken?: string): Promise<Product>
        
        /**
        * Modifies many Products at the same time by ID.
        **/
        bulkReplace(input: Array<Product>, userToken?: string): Promise<Array<Product>>
        
        /**
        * Replaces a single Product by ID.
        **/
        replace(id: string, input: Product, userToken?: string): Promise<Product>
        
        /**
        * Modifies many Products at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<Product>, userToken?: string): Promise<number>
        
        /**
        * Modifies a Product by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<Product>, userToken?: string): Promise<EntryChange<Product>>
        
        /**
        * Modifies a Product by ID, returning the new value.
        **/
        modify(id: string, input: Modification<Product>, userToken?: string): Promise<Product>
        
        /**
        * Deletes all matching Products, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<Product>, userToken?: string): Promise<number>
        
        /**
        * Deletes a Product by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of Products matching the given condition.
        **/
        count(input: Condition<Product>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of Products matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<Product>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Products matching the given condition.
        **/
        aggregate(input: AggregateQuery<Product>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Products matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<Product>, userToken?: string): Promise<Record<string, number | null | undefined>>
        
        /**
        * Returns a minimized model for page building purposes
        **/
        minimal(input: Query<Product>, userToken?: string): Promise<Array<ProductMinimized>>
    }
    readonly productTag: {
        
        /**
        * Gets a default ProductTag that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<ProductTag>
        
        /**
        * Gets a list of ProductTags that match the given query.
        **/
        query(input: Query<ProductTag>, userToken?: string): Promise<Array<ProductTag>>
        
        /**
        * Gets parts of ProductTags that match the given query.
        **/
        queryPartial(input: QueryPartial<ProductTag>, userToken?: string): Promise<Array<DeepPartial<ProductTag>>>
        
        /**
        * Gets a single ProductTag by ID.
        **/
        detail(id: string, userToken?: string): Promise<ProductTag>
        
        /**
        * Creates multiple ProductTags at the same time.
        **/
        insertBulk(input: Array<ProductTag>, userToken?: string): Promise<Array<ProductTag>>
        
        /**
        * Creates a new ProductTag
        **/
        insert(input: ProductTag, userToken?: string): Promise<ProductTag>
        
        /**
        * Creates or updates a ProductTag
        **/
        upsert(id: string, input: ProductTag, userToken?: string): Promise<ProductTag>
        
        /**
        * Modifies many ProductTags at the same time by ID.
        **/
        bulkReplace(input: Array<ProductTag>, userToken?: string): Promise<Array<ProductTag>>
        
        /**
        * Replaces a single ProductTag by ID.
        **/
        replace(id: string, input: ProductTag, userToken?: string): Promise<ProductTag>
        
        /**
        * Modifies many ProductTags at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<ProductTag>, userToken?: string): Promise<number>
        
        /**
        * Modifies a ProductTag by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<ProductTag>, userToken?: string): Promise<EntryChange<ProductTag>>
        
        /**
        * Modifies a ProductTag by ID, returning the new value.
        **/
        modify(id: string, input: Modification<ProductTag>, userToken?: string): Promise<ProductTag>
        
        /**
        * Deletes all matching ProductTags, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<ProductTag>, userToken?: string): Promise<number>
        
        /**
        * Deletes a ProductTag by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of ProductTags matching the given condition.
        **/
        count(input: Condition<ProductTag>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of ProductTags matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<ProductTag>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of ProductTags matching the given condition.
        **/
        aggregate(input: AggregateQuery<ProductTag>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of ProductTags matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<ProductTag>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly productVariant: {
        
        /**
        * Gets a default ProductVariant that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<ProductVariant>
        
        /**
        * Gets a list of ProductVariants that match the given query.
        **/
        query(input: Query<ProductVariant>, userToken?: string): Promise<Array<ProductVariant>>
        
        /**
        * Gets parts of ProductVariants that match the given query.
        **/
        queryPartial(input: QueryPartial<ProductVariant>, userToken?: string): Promise<Array<DeepPartial<ProductVariant>>>
        
        /**
        * Gets a single ProductVariant by ID.
        **/
        detail(id: UUID, userToken?: string): Promise<ProductVariant>
        
        /**
        * Creates multiple ProductVariants at the same time.
        **/
        insertBulk(input: Array<ProductVariant>, userToken?: string): Promise<Array<ProductVariant>>
        
        /**
        * Creates a new ProductVariant
        **/
        insert(input: ProductVariant, userToken?: string): Promise<ProductVariant>
        
        /**
        * Creates or updates a ProductVariant
        **/
        upsert(id: UUID, input: ProductVariant, userToken?: string): Promise<ProductVariant>
        
        /**
        * Modifies many ProductVariants at the same time by ID.
        **/
        bulkReplace(input: Array<ProductVariant>, userToken?: string): Promise<Array<ProductVariant>>
        
        /**
        * Replaces a single ProductVariant by ID.
        **/
        replace(id: UUID, input: ProductVariant, userToken?: string): Promise<ProductVariant>
        
        /**
        * Modifies many ProductVariants at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<ProductVariant>, userToken?: string): Promise<number>
        
        /**
        * Modifies a ProductVariant by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<ProductVariant>, userToken?: string): Promise<EntryChange<ProductVariant>>
        
        /**
        * Modifies a ProductVariant by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<ProductVariant>, userToken?: string): Promise<ProductVariant>
        
        /**
        * Deletes all matching ProductVariants, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<ProductVariant>, userToken?: string): Promise<number>
        
        /**
        * Deletes a ProductVariant by id.
        **/
        delete(id: UUID, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of ProductVariants matching the given condition.
        **/
        count(input: Condition<ProductVariant>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of ProductVariants matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<ProductVariant>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of ProductVariants matching the given condition.
        **/
        aggregate(input: AggregateQuery<ProductVariant>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of ProductVariants matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<ProductVariant>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly review: {
        
        /**
        * Gets a default Review that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<Review>
        
        /**
        * Gets a list of Reviews that match the given query.
        **/
        query(input: Query<Review>, userToken?: string): Promise<Array<Review>>
        
        /**
        * Gets parts of Reviews that match the given query.
        **/
        queryPartial(input: QueryPartial<Review>, userToken?: string): Promise<Array<DeepPartial<Review>>>
        
        /**
        * Gets a single Review by ID.
        **/
        detail(id: UUID, userToken?: string): Promise<Review>
        
        /**
        * Creates multiple Reviews at the same time.
        **/
        insertBulk(input: Array<Review>, userToken?: string): Promise<Array<Review>>
        
        /**
        * Creates a new Review
        **/
        insert(input: Review, userToken?: string): Promise<Review>
        
        /**
        * Creates or updates a Review
        **/
        upsert(id: UUID, input: Review, userToken?: string): Promise<Review>
        
        /**
        * Modifies many Reviews at the same time by ID.
        **/
        bulkReplace(input: Array<Review>, userToken?: string): Promise<Array<Review>>
        
        /**
        * Replaces a single Review by ID.
        **/
        replace(id: UUID, input: Review, userToken?: string): Promise<Review>
        
        /**
        * Modifies many Reviews at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<Review>, userToken?: string): Promise<number>
        
        /**
        * Modifies a Review by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<Review>, userToken?: string): Promise<EntryChange<Review>>
        
        /**
        * Modifies a Review by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<Review>, userToken?: string): Promise<Review>
        
        /**
        * Deletes all matching Reviews, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<Review>, userToken?: string): Promise<number>
        
        /**
        * Deletes a Review by id.
        **/
        delete(id: UUID, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of Reviews matching the given condition.
        **/
        count(input: Condition<Review>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of Reviews matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<Review>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Reviews matching the given condition.
        **/
        aggregate(input: AggregateQuery<Review>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Reviews matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<Review>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly sale: {
        
        /**
        * Gets a default Sale that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<Sale>
        
        /**
        * Gets a list of Sales that match the given query.
        **/
        query(input: Query<Sale>, userToken?: string): Promise<Array<Sale>>
        
        /**
        * Gets parts of Sales that match the given query.
        **/
        queryPartial(input: QueryPartial<Sale>, userToken?: string): Promise<Array<DeepPartial<Sale>>>
        
        /**
        * Gets a single Sale by ID.
        **/
        detail(id: string, userToken?: string): Promise<Sale>
        
        /**
        * Creates multiple Sales at the same time.
        **/
        insertBulk(input: Array<Sale>, userToken?: string): Promise<Array<Sale>>
        
        /**
        * Creates a new Sale
        **/
        insert(input: Sale, userToken?: string): Promise<Sale>
        
        /**
        * Creates or updates a Sale
        **/
        upsert(id: string, input: Sale, userToken?: string): Promise<Sale>
        
        /**
        * Modifies many Sales at the same time by ID.
        **/
        bulkReplace(input: Array<Sale>, userToken?: string): Promise<Array<Sale>>
        
        /**
        * Replaces a single Sale by ID.
        **/
        replace(id: string, input: Sale, userToken?: string): Promise<Sale>
        
        /**
        * Modifies many Sales at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<Sale>, userToken?: string): Promise<number>
        
        /**
        * Modifies a Sale by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<Sale>, userToken?: string): Promise<EntryChange<Sale>>
        
        /**
        * Modifies a Sale by ID, returning the new value.
        **/
        modify(id: string, input: Modification<Sale>, userToken?: string): Promise<Sale>
        
        /**
        * Deletes all matching Sales, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<Sale>, userToken?: string): Promise<number>
        
        /**
        * Deletes a Sale by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of Sales matching the given condition.
        **/
        count(input: Condition<Sale>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of Sales matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<Sale>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Sales matching the given condition.
        **/
        aggregate(input: AggregateQuery<Sale>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Sales matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<Sale>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly shopTalk: {
        
        /**
        * Gets a default ShopTalk that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<ShopTalk>
        
        /**
        * Gets a list of ShopTalks that match the given query.
        **/
        query(input: Query<ShopTalk>, userToken?: string): Promise<Array<ShopTalk>>
        
        /**
        * Gets parts of ShopTalks that match the given query.
        **/
        queryPartial(input: QueryPartial<ShopTalk>, userToken?: string): Promise<Array<DeepPartial<ShopTalk>>>
        
        /**
        * Gets a single ShopTalk by ID.
        **/
        detail(id: UUID, userToken?: string): Promise<ShopTalk>
        
        /**
        * Creates multiple ShopTalks at the same time.
        **/
        insertBulk(input: Array<ShopTalk>, userToken?: string): Promise<Array<ShopTalk>>
        
        /**
        * Creates a new ShopTalk
        **/
        insert(input: ShopTalk, userToken?: string): Promise<ShopTalk>
        
        /**
        * Creates or updates a ShopTalk
        **/
        upsert(id: UUID, input: ShopTalk, userToken?: string): Promise<ShopTalk>
        
        /**
        * Modifies many ShopTalks at the same time by ID.
        **/
        bulkReplace(input: Array<ShopTalk>, userToken?: string): Promise<Array<ShopTalk>>
        
        /**
        * Replaces a single ShopTalk by ID.
        **/
        replace(id: UUID, input: ShopTalk, userToken?: string): Promise<ShopTalk>
        
        /**
        * Modifies many ShopTalks at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<ShopTalk>, userToken?: string): Promise<number>
        
        /**
        * Modifies a ShopTalk by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<ShopTalk>, userToken?: string): Promise<EntryChange<ShopTalk>>
        
        /**
        * Modifies a ShopTalk by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<ShopTalk>, userToken?: string): Promise<ShopTalk>
        
        /**
        * Deletes all matching ShopTalks, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<ShopTalk>, userToken?: string): Promise<number>
        
        /**
        * Deletes a ShopTalk by id.
        **/
        delete(id: UUID, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of ShopTalks matching the given condition.
        **/
        count(input: Condition<ShopTalk>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of ShopTalks matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<ShopTalk>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of ShopTalks matching the given condition.
        **/
        aggregate(input: AggregateQuery<ShopTalk>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of ShopTalks matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<ShopTalk>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly syncProgress: {
        
        /**
        * Gets a default SyncProgress that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken?: string): Promise<SyncProgress>
        
        /**
        * Gets a list of SyncProgresss that match the given query.
        **/
        query(input: Query<SyncProgress>, userToken?: string): Promise<Array<SyncProgress>>
        
        /**
        * Gets parts of SyncProgresss that match the given query.
        **/
        queryPartial(input: QueryPartial<SyncProgress>, userToken?: string): Promise<Array<DeepPartial<SyncProgress>>>
        
        /**
        * Gets a single SyncProgress by ID.
        **/
        detail(id: string, userToken?: string): Promise<SyncProgress>
        
        /**
        * Creates multiple SyncProgresss at the same time.
        **/
        insertBulk(input: Array<SyncProgress>, userToken?: string): Promise<Array<SyncProgress>>
        
        /**
        * Creates a new SyncProgress
        **/
        insert(input: SyncProgress, userToken?: string): Promise<SyncProgress>
        
        /**
        * Creates or updates a SyncProgress
        **/
        upsert(id: string, input: SyncProgress, userToken?: string): Promise<SyncProgress>
        
        /**
        * Modifies many SyncProgresss at the same time by ID.
        **/
        bulkReplace(input: Array<SyncProgress>, userToken?: string): Promise<Array<SyncProgress>>
        
        /**
        * Replaces a single SyncProgress by ID.
        **/
        replace(id: string, input: SyncProgress, userToken?: string): Promise<SyncProgress>
        
        /**
        * Modifies many SyncProgresss at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<SyncProgress>, userToken?: string): Promise<number>
        
        /**
        * Modifies a SyncProgress by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: string, input: Modification<SyncProgress>, userToken?: string): Promise<EntryChange<SyncProgress>>
        
        /**
        * Modifies a SyncProgress by ID, returning the new value.
        **/
        modify(id: string, input: Modification<SyncProgress>, userToken?: string): Promise<SyncProgress>
        
        /**
        * Deletes all matching SyncProgresss, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<SyncProgress>, userToken?: string): Promise<number>
        
        /**
        * Deletes a SyncProgress by id.
        **/
        delete(id: string, userToken?: string): Promise<void>
        
        /**
        * Gets the total number of SyncProgresss matching the given condition.
        **/
        count(input: Condition<SyncProgress>, userToken?: string): Promise<number>
        
        /**
        * Gets the total number of SyncProgresss matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<SyncProgress>, userToken?: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of SyncProgresss matching the given condition.
        **/
        aggregate(input: AggregateQuery<SyncProgress>, userToken?: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of SyncProgresss matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<SyncProgress>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly user: {
        
        /**
        * Gets a default User that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default(userToken: string): Promise<User>
        
        /**
        * Gets a list of Users that match the given query.
        **/
        query(input: Query<User>, userToken: string): Promise<Array<User>>
        
        /**
        * Gets parts of Users that match the given query.
        **/
        queryPartial(input: QueryPartial<User>, userToken: string): Promise<Array<DeepPartial<User>>>
        
        /**
        * Gets a single User by ID.
        **/
        detail(id: UUID, userToken: string): Promise<User>
        
        /**
        * Creates multiple Users at the same time.
        **/
        insertBulk(input: Array<User>, userToken: string): Promise<Array<User>>
        
        /**
        * Creates a new User
        **/
        insert(input: User, userToken: string): Promise<User>
        
        /**
        * Creates or updates a User
        **/
        upsert(id: UUID, input: User, userToken: string): Promise<User>
        
        /**
        * Modifies many Users at the same time by ID.
        **/
        bulkReplace(input: Array<User>, userToken: string): Promise<Array<User>>
        
        /**
        * Replaces a single User by ID.
        **/
        replace(id: UUID, input: User, userToken: string): Promise<User>
        
        /**
        * Modifies many Users at the same time.  Returns the number of changed items.
        **/
        bulkModify(input: MassModification<User>, userToken: string): Promise<number>
        
        /**
        * Modifies a User by ID, returning both the previous value and new value.
        **/
        modifyWithDiff(id: UUID, input: Modification<User>, userToken: string): Promise<EntryChange<User>>
        
        /**
        * Modifies a User by ID, returning the new value.
        **/
        modify(id: UUID, input: Modification<User>, userToken: string): Promise<User>
        
        /**
        * Deletes all matching Users, returning the number of deleted items.
        **/
        bulkDelete(input: Condition<User>, userToken: string): Promise<number>
        
        /**
        * Deletes a User by id.
        **/
        delete(id: UUID, userToken: string): Promise<void>
        
        /**
        * Gets the total number of Users matching the given condition.
        **/
        count(input: Condition<User>, userToken: string): Promise<number>
        
        /**
        * Gets the total number of Users matching the given condition divided by group.
        **/
        groupCount(input: GroupCountQuery<User>, userToken: string): Promise<Record<string, number>>
        
        /**
        * Aggregates a property of Users matching the given condition.
        **/
        aggregate(input: AggregateQuery<User>, userToken: string): Promise<number | null | undefined>
        
        /**
        * Aggregates a property of Users matching the given condition divided by group.
        **/
        groupAggregate(input: GroupAggregateQuery<User>, userToken: string): Promise<Record<string, number | null | undefined>>
    }
}



export class UserSession {
    constructor(public api: Api, public userToken: string) {}
    
    /**
    * Begin Sync
    **/
    beginSync(input: SyncRequest): Promise<SyncProgress | null | undefined> { return this.api.beginSync(input, this.userToken) } 
    
    /**
    * Begin Sync Temecula
    **/
    beginSyncTemecula(input: SyncRequest): Promise<void> { return this.api.beginSyncTemecula(input, this.userToken) } 
    
    /**
    * Returns the pending order tied to the Stripe Payment Intent Client Secret
    **/
    getPendingOrder(clientSecret: string): Promise<PendingOrder> { return this.api.getPendingOrder(clientSecret, this.userToken) } 
    
    /**
    * Gets the current status of the server
    **/
    getServerHealth(): Promise<ServerHealth> { return this.api.getServerHealth(this.userToken) } 
    
    /**
    * Get Version
    **/
    getVersion(): Promise<string> { return this.api.getVersion() } 
    
    /**
    * Returns a url for a csv with all product data int the Google Merchant Listing format.
    **/
    merchantListings(): Promise<string> { return this.api.merchantListings(this.userToken) } 
    
    /**
    * Pick a random user for a giveaway
    **/
    pickARandomUserForAGiveaway(): Promise<User> { return this.api.pickARandomUserForAGiveaway(this.userToken) } 
    
    /**
    * Place Order
    **/
    placeOrder(input: LegacyOrderRequest): Promise<LegacyPaymentResponse> { return this.api.placeOrder(input, this.userToken) } 
    
    /**
    * Place Order v2
    **/
    placeOrderV2(input: StartOrderRequest): Promise<string> { return this.api.placeOrderV2(input, this.userToken) } 
    
    /**
    * Adds the fitment provided to the relevant products.
    **/
    submitFitment(brandId: string, input: Array<Fitment>): Promise<void> { return this.api.submitFitment(brandId, input, this.userToken) } 
    
    /**
    * Adds the fitment provided to the relevant products.
    **/
    submitFitmentYearRange(brandId: string, input: Array<FitmentYearRange>): Promise<void> { return this.api.submitFitmentYearRange(brandId, input, this.userToken) } 
    
    /**
    * Brings over all the categories from the old woocommerce site
    **/
    syncWoocommerceCategories(): Promise<void> { return this.api.syncWoocommerceCategories(this.userToken) } 
    
    /**
    * Brings over all the categories for each product from the old woocommerce site
    **/
    syncWoocommerceProductCategories(input: SyncRequest): Promise<SyncProgress> { return this.api.syncWoocommerceProductCategories(input, this.userToken) } 
    
    /**
    * Upload a file to make a request later.  Times out in around 10 minutes.
    **/
    uploadFileForRequest(): Promise<UploadInformation> { return this.api.uploadFileForRequest() } 
    readonly auth = {
        
        /**
        * Creates a new token for the user, which can be used to authenticate with the API via the header 'Authorization: Bearer [insert token here]'.
        **/
        refreshToken: (): Promise<string> => { return this.api.auth.refreshToken(this.userToken) }, 
        
        /**
        * Retrieves the user that you currently authenticated as.
        **/
        getSelf: (): Promise<User> => { return this.api.auth.getSelf(this.userToken) }, 
        
        /**
        * Creates a token for a new, anonymous user.  The token can be used to authenticate with the API via the header 'Authorization: Bearer [insert token here]
        **/
        anonymousToken: (): Promise<string> => { return this.api.auth.anonymousToken(this.userToken) }, 
        
        /**
        * Sends a login email to the given address.  The email will contain both a link to instantly log in and a PIN that can be entered to log in.
        **/
        emailLoginLink: (input: string): Promise<void> => { return this.api.auth.emailLoginLink(input) }, 
        
        /**
        * Logs in to the given account with a PIN that was provided in an email sent earlier.  Note that the PIN expires in 15 minutes, and you are only permitted 5 attempts.
        **/
        emailPINLogin: (input: EmailPinLogin): Promise<string> => { return this.api.auth.emailPINLogin(input) }, 
    }
    readonly brand = {
        
        /**
        * Gets a default Brand that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<Brand> => { return this.api.brand.default(this.userToken) }, 
        
        /**
        * Gets a list of Brands that match the given query.
        **/
        query: (input: Query<Brand>): Promise<Array<Brand>> => { return this.api.brand.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Brands that match the given query.
        **/
        queryPartial: (input: QueryPartial<Brand>): Promise<Array<DeepPartial<Brand>>> => { return this.api.brand.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single Brand by ID.
        **/
        detail: (id: string): Promise<Brand> => { return this.api.brand.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Brands at the same time.
        **/
        insertBulk: (input: Array<Brand>): Promise<Array<Brand>> => { return this.api.brand.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new Brand
        **/
        insert: (input: Brand): Promise<Brand> => { return this.api.brand.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a Brand
        **/
        upsert: (id: string, input: Brand): Promise<Brand> => { return this.api.brand.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Brands at the same time by ID.
        **/
        bulkReplace: (input: Array<Brand>): Promise<Array<Brand>> => { return this.api.brand.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single Brand by ID.
        **/
        replace: (id: string, input: Brand): Promise<Brand> => { return this.api.brand.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Brands at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Brand>): Promise<number> => { return this.api.brand.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a Brand by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<Brand>): Promise<EntryChange<Brand>> => { return this.api.brand.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a Brand by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<Brand>): Promise<Brand> => { return this.api.brand.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Brands, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Brand>): Promise<number> => { return this.api.brand.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a Brand by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.brand.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Brands matching the given condition.
        **/
        count: (input: Condition<Brand>): Promise<number> => { return this.api.brand.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Brands matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Brand>): Promise<Record<string, number>> => { return this.api.brand.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Brands matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Brand>): Promise<number | null | undefined> => { return this.api.brand.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Brands matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Brand>): Promise<Record<string, number | null | undefined>> => { return this.api.brand.groupAggregate(input, this.userToken) }, 
    }
    readonly changeItem = {
        
        /**
        * Gets a default ChangeItem that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<ChangeItem> => { return this.api.changeItem.default(this.userToken) }, 
        
        /**
        * Gets a list of ChangeItems that match the given query.
        **/
        query: (input: Query<ChangeItem>): Promise<Array<ChangeItem>> => { return this.api.changeItem.query(input, this.userToken) }, 
        
        /**
        * Gets parts of ChangeItems that match the given query.
        **/
        queryPartial: (input: QueryPartial<ChangeItem>): Promise<Array<DeepPartial<ChangeItem>>> => { return this.api.changeItem.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single ChangeItem by ID.
        **/
        detail: (id: string): Promise<ChangeItem> => { return this.api.changeItem.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple ChangeItems at the same time.
        **/
        insertBulk: (input: Array<ChangeItem>): Promise<Array<ChangeItem>> => { return this.api.changeItem.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new ChangeItem
        **/
        insert: (input: ChangeItem): Promise<ChangeItem> => { return this.api.changeItem.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a ChangeItem
        **/
        upsert: (id: string, input: ChangeItem): Promise<ChangeItem> => { return this.api.changeItem.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many ChangeItems at the same time by ID.
        **/
        bulkReplace: (input: Array<ChangeItem>): Promise<Array<ChangeItem>> => { return this.api.changeItem.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single ChangeItem by ID.
        **/
        replace: (id: string, input: ChangeItem): Promise<ChangeItem> => { return this.api.changeItem.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many ChangeItems at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ChangeItem>): Promise<number> => { return this.api.changeItem.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a ChangeItem by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ChangeItem>): Promise<EntryChange<ChangeItem>> => { return this.api.changeItem.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a ChangeItem by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ChangeItem>): Promise<ChangeItem> => { return this.api.changeItem.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching ChangeItems, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ChangeItem>): Promise<number> => { return this.api.changeItem.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a ChangeItem by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.changeItem.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of ChangeItems matching the given condition.
        **/
        count: (input: Condition<ChangeItem>): Promise<number> => { return this.api.changeItem.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of ChangeItems matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ChangeItem>): Promise<Record<string, number>> => { return this.api.changeItem.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ChangeItems matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ChangeItem>): Promise<number | null | undefined> => { return this.api.changeItem.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ChangeItems matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ChangeItem>): Promise<Record<string, number | null | undefined>> => { return this.api.changeItem.groupAggregate(input, this.userToken) }, 
    }
    readonly dynamicSettings = {
        
        /**
        * Gets a default DynamicSettings that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<DynamicSettings> => { return this.api.dynamicSettings.default(this.userToken) }, 
        
        /**
        * Gets a list of DynamicSettingss that match the given query.
        **/
        query: (input: Query<DynamicSettings>): Promise<Array<DynamicSettings>> => { return this.api.dynamicSettings.query(input, this.userToken) }, 
        
        /**
        * Gets parts of DynamicSettingss that match the given query.
        **/
        queryPartial: (input: QueryPartial<DynamicSettings>): Promise<Array<DeepPartial<DynamicSettings>>> => { return this.api.dynamicSettings.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single DynamicSettings by ID.
        **/
        detail: (id: string): Promise<DynamicSettings> => { return this.api.dynamicSettings.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple DynamicSettingss at the same time.
        **/
        insertBulk: (input: Array<DynamicSettings>): Promise<Array<DynamicSettings>> => { return this.api.dynamicSettings.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new DynamicSettings
        **/
        insert: (input: DynamicSettings): Promise<DynamicSettings> => { return this.api.dynamicSettings.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a DynamicSettings
        **/
        upsert: (id: string, input: DynamicSettings): Promise<DynamicSettings> => { return this.api.dynamicSettings.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many DynamicSettingss at the same time by ID.
        **/
        bulkReplace: (input: Array<DynamicSettings>): Promise<Array<DynamicSettings>> => { return this.api.dynamicSettings.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single DynamicSettings by ID.
        **/
        replace: (id: string, input: DynamicSettings): Promise<DynamicSettings> => { return this.api.dynamicSettings.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many DynamicSettingss at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<DynamicSettings>): Promise<number> => { return this.api.dynamicSettings.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a DynamicSettings by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<DynamicSettings>): Promise<EntryChange<DynamicSettings>> => { return this.api.dynamicSettings.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a DynamicSettings by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<DynamicSettings>): Promise<DynamicSettings> => { return this.api.dynamicSettings.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching DynamicSettingss, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<DynamicSettings>): Promise<number> => { return this.api.dynamicSettings.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a DynamicSettings by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.dynamicSettings.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of DynamicSettingss matching the given condition.
        **/
        count: (input: Condition<DynamicSettings>): Promise<number> => { return this.api.dynamicSettings.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of DynamicSettingss matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<DynamicSettings>): Promise<Record<string, number>> => { return this.api.dynamicSettings.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of DynamicSettingss matching the given condition.
        **/
        aggregate: (input: AggregateQuery<DynamicSettings>): Promise<number | null | undefined> => { return this.api.dynamicSettings.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of DynamicSettingss matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<DynamicSettings>): Promise<Record<string, number | null | undefined>> => { return this.api.dynamicSettings.groupAggregate(input, this.userToken) }, 
    }
    readonly giveaway = {
        
        /**
        * Gets a default Giveaway that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<Giveaway> => { return this.api.giveaway.default(this.userToken) }, 
        
        /**
        * Gets a list of Giveaways that match the given query.
        **/
        query: (input: Query<Giveaway>): Promise<Array<Giveaway>> => { return this.api.giveaway.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Giveaways that match the given query.
        **/
        queryPartial: (input: QueryPartial<Giveaway>): Promise<Array<DeepPartial<Giveaway>>> => { return this.api.giveaway.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single Giveaway by ID.
        **/
        detail: (id: UUID): Promise<Giveaway> => { return this.api.giveaway.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Giveaways at the same time.
        **/
        insertBulk: (input: Array<Giveaway>): Promise<Array<Giveaway>> => { return this.api.giveaway.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new Giveaway
        **/
        insert: (input: Giveaway): Promise<Giveaway> => { return this.api.giveaway.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a Giveaway
        **/
        upsert: (id: UUID, input: Giveaway): Promise<Giveaway> => { return this.api.giveaway.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Giveaways at the same time by ID.
        **/
        bulkReplace: (input: Array<Giveaway>): Promise<Array<Giveaway>> => { return this.api.giveaway.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single Giveaway by ID.
        **/
        replace: (id: UUID, input: Giveaway): Promise<Giveaway> => { return this.api.giveaway.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Giveaways at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Giveaway>): Promise<number> => { return this.api.giveaway.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a Giveaway by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<Giveaway>): Promise<EntryChange<Giveaway>> => { return this.api.giveaway.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a Giveaway by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<Giveaway>): Promise<Giveaway> => { return this.api.giveaway.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Giveaways, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Giveaway>): Promise<number> => { return this.api.giveaway.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a Giveaway by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.giveaway.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Giveaways matching the given condition.
        **/
        count: (input: Condition<Giveaway>): Promise<number> => { return this.api.giveaway.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Giveaways matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Giveaway>): Promise<Record<string, number>> => { return this.api.giveaway.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Giveaways matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Giveaway>): Promise<number | null | undefined> => { return this.api.giveaway.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Giveaways matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Giveaway>): Promise<Record<string, number | null | undefined>> => { return this.api.giveaway.groupAggregate(input, this.userToken) }, 
    }
    readonly knownModel = {
        
        /**
        * Gets a default KnownModel that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<KnownModel> => { return this.api.knownModel.default(this.userToken) }, 
        
        /**
        * Gets a list of KnownModels that match the given query.
        **/
        query: (input: Query<KnownModel>): Promise<Array<KnownModel>> => { return this.api.knownModel.query(input, this.userToken) }, 
        
        /**
        * Gets parts of KnownModels that match the given query.
        **/
        queryPartial: (input: QueryPartial<KnownModel>): Promise<Array<DeepPartial<KnownModel>>> => { return this.api.knownModel.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single KnownModel by ID.
        **/
        detail: (id: string): Promise<KnownModel> => { return this.api.knownModel.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple KnownModels at the same time.
        **/
        insertBulk: (input: Array<KnownModel>): Promise<Array<KnownModel>> => { return this.api.knownModel.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new KnownModel
        **/
        insert: (input: KnownModel): Promise<KnownModel> => { return this.api.knownModel.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a KnownModel
        **/
        upsert: (id: string, input: KnownModel): Promise<KnownModel> => { return this.api.knownModel.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many KnownModels at the same time by ID.
        **/
        bulkReplace: (input: Array<KnownModel>): Promise<Array<KnownModel>> => { return this.api.knownModel.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single KnownModel by ID.
        **/
        replace: (id: string, input: KnownModel): Promise<KnownModel> => { return this.api.knownModel.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many KnownModels at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<KnownModel>): Promise<number> => { return this.api.knownModel.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a KnownModel by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<KnownModel>): Promise<EntryChange<KnownModel>> => { return this.api.knownModel.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a KnownModel by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<KnownModel>): Promise<KnownModel> => { return this.api.knownModel.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching KnownModels, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<KnownModel>): Promise<number> => { return this.api.knownModel.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a KnownModel by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.knownModel.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of KnownModels matching the given condition.
        **/
        count: (input: Condition<KnownModel>): Promise<number> => { return this.api.knownModel.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of KnownModels matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<KnownModel>): Promise<Record<string, number>> => { return this.api.knownModel.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of KnownModels matching the given condition.
        **/
        aggregate: (input: AggregateQuery<KnownModel>): Promise<number | null | undefined> => { return this.api.knownModel.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of KnownModels matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<KnownModel>): Promise<Record<string, number | null | undefined>> => { return this.api.knownModel.groupAggregate(input, this.userToken) }, 
    }
    readonly legacyPayment = {
        
        /**
        * Gets a default Legacy-paymentRequest that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<ExternalAsyncTaskRequest> => { return this.api.legacyPayment.default(this.userToken) }, 
        
        /**
        * Gets a list of Legacy-paymentRequests that match the given query.
        **/
        query: (input: Query<ExternalAsyncTaskRequest>): Promise<Array<ExternalAsyncTaskRequest>> => { return this.api.legacyPayment.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Legacy-paymentRequests that match the given query.
        **/
        queryPartial: (input: QueryPartial<ExternalAsyncTaskRequest>): Promise<Array<DeepPartial<ExternalAsyncTaskRequest>>> => { return this.api.legacyPayment.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single Legacy-paymentRequest by ID.
        **/
        detail: (id: string): Promise<ExternalAsyncTaskRequest> => { return this.api.legacyPayment.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Legacy-paymentRequests at the same time.
        **/
        insertBulk: (input: Array<ExternalAsyncTaskRequest>): Promise<Array<ExternalAsyncTaskRequest>> => { return this.api.legacyPayment.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new Legacy-paymentRequest
        **/
        insert: (input: ExternalAsyncTaskRequest): Promise<ExternalAsyncTaskRequest> => { return this.api.legacyPayment.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a Legacy-paymentRequest
        **/
        upsert: (id: string, input: ExternalAsyncTaskRequest): Promise<ExternalAsyncTaskRequest> => { return this.api.legacyPayment.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Legacy-paymentRequests at the same time by ID.
        **/
        bulkReplace: (input: Array<ExternalAsyncTaskRequest>): Promise<Array<ExternalAsyncTaskRequest>> => { return this.api.legacyPayment.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single Legacy-paymentRequest by ID.
        **/
        replace: (id: string, input: ExternalAsyncTaskRequest): Promise<ExternalAsyncTaskRequest> => { return this.api.legacyPayment.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Legacy-paymentRequests at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ExternalAsyncTaskRequest>): Promise<number> => { return this.api.legacyPayment.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a Legacy-paymentRequest by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ExternalAsyncTaskRequest>): Promise<EntryChange<ExternalAsyncTaskRequest>> => { return this.api.legacyPayment.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a Legacy-paymentRequest by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ExternalAsyncTaskRequest>): Promise<ExternalAsyncTaskRequest> => { return this.api.legacyPayment.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Legacy-paymentRequests, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ExternalAsyncTaskRequest>): Promise<number> => { return this.api.legacyPayment.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a Legacy-paymentRequest by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.legacyPayment.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Legacy-paymentRequests matching the given condition.
        **/
        count: (input: Condition<ExternalAsyncTaskRequest>): Promise<number> => { return this.api.legacyPayment.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Legacy-paymentRequests matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ExternalAsyncTaskRequest>): Promise<Record<string, number>> => { return this.api.legacyPayment.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Legacy-paymentRequests matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ExternalAsyncTaskRequest>): Promise<number | null | undefined> => { return this.api.legacyPayment.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Legacy-paymentRequests matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ExternalAsyncTaskRequest>): Promise<Record<string, number | null | undefined>> => { return this.api.legacyPayment.groupAggregate(input, this.userToken) }, 
        
        /**
        * Manually recheck tasks
        **/
        manuallyRecheckTasks: (): Promise<void> => { return this.api.legacyPayment.manuallyRecheckTasks(this.userToken) }, 
        
        /**
        * Manually recheck a task
        **/
        manuallyRecheckATask: (id: string): Promise<void> => { return this.api.legacyPayment.manuallyRecheckATask(id, this.userToken) }, 
    }
    readonly oEMAssembly = {
        
        /**
        * Gets a default OEMAssembly that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<OEMAssembly> => { return this.api.oEMAssembly.default(this.userToken) }, 
        
        /**
        * Gets a list of OEMAssemblys that match the given query.
        **/
        query: (input: Query<OEMAssembly>): Promise<Array<OEMAssembly>> => { return this.api.oEMAssembly.query(input, this.userToken) }, 
        
        /**
        * Gets parts of OEMAssemblys that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMAssembly>): Promise<Array<DeepPartial<OEMAssembly>>> => { return this.api.oEMAssembly.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single OEMAssembly by ID.
        **/
        detail: (id: string): Promise<OEMAssembly> => { return this.api.oEMAssembly.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple OEMAssemblys at the same time.
        **/
        insertBulk: (input: Array<OEMAssembly>): Promise<Array<OEMAssembly>> => { return this.api.oEMAssembly.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new OEMAssembly
        **/
        insert: (input: OEMAssembly): Promise<OEMAssembly> => { return this.api.oEMAssembly.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a OEMAssembly
        **/
        upsert: (id: string, input: OEMAssembly): Promise<OEMAssembly> => { return this.api.oEMAssembly.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMAssemblys at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMAssembly>): Promise<Array<OEMAssembly>> => { return this.api.oEMAssembly.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single OEMAssembly by ID.
        **/
        replace: (id: string, input: OEMAssembly): Promise<OEMAssembly> => { return this.api.oEMAssembly.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMAssemblys at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMAssembly>): Promise<number> => { return this.api.oEMAssembly.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a OEMAssembly by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<OEMAssembly>): Promise<EntryChange<OEMAssembly>> => { return this.api.oEMAssembly.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a OEMAssembly by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<OEMAssembly>): Promise<OEMAssembly> => { return this.api.oEMAssembly.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching OEMAssemblys, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMAssembly>): Promise<number> => { return this.api.oEMAssembly.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a OEMAssembly by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.oEMAssembly.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of OEMAssemblys matching the given condition.
        **/
        count: (input: Condition<OEMAssembly>): Promise<number> => { return this.api.oEMAssembly.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of OEMAssemblys matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMAssembly>): Promise<Record<string, number>> => { return this.api.oEMAssembly.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMAssemblys matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMAssembly>): Promise<number | null | undefined> => { return this.api.oEMAssembly.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMAssemblys matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMAssembly>): Promise<Record<string, number | null | undefined>> => { return this.api.oEMAssembly.groupAggregate(input, this.userToken) }, 
    }
    readonly oEMMake = {
        
        /**
        * Gets a default OEMMake that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<OEMMake> => { return this.api.oEMMake.default(this.userToken) }, 
        
        /**
        * Gets a list of OEMMakes that match the given query.
        **/
        query: (input: Query<OEMMake>): Promise<Array<OEMMake>> => { return this.api.oEMMake.query(input, this.userToken) }, 
        
        /**
        * Gets parts of OEMMakes that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMMake>): Promise<Array<DeepPartial<OEMMake>>> => { return this.api.oEMMake.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single OEMMake by ID.
        **/
        detail: (id: string): Promise<OEMMake> => { return this.api.oEMMake.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple OEMMakes at the same time.
        **/
        insertBulk: (input: Array<OEMMake>): Promise<Array<OEMMake>> => { return this.api.oEMMake.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new OEMMake
        **/
        insert: (input: OEMMake): Promise<OEMMake> => { return this.api.oEMMake.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a OEMMake
        **/
        upsert: (id: string, input: OEMMake): Promise<OEMMake> => { return this.api.oEMMake.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMMakes at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMMake>): Promise<Array<OEMMake>> => { return this.api.oEMMake.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single OEMMake by ID.
        **/
        replace: (id: string, input: OEMMake): Promise<OEMMake> => { return this.api.oEMMake.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMMakes at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMMake>): Promise<number> => { return this.api.oEMMake.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a OEMMake by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<OEMMake>): Promise<EntryChange<OEMMake>> => { return this.api.oEMMake.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a OEMMake by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<OEMMake>): Promise<OEMMake> => { return this.api.oEMMake.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching OEMMakes, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMMake>): Promise<number> => { return this.api.oEMMake.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a OEMMake by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.oEMMake.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of OEMMakes matching the given condition.
        **/
        count: (input: Condition<OEMMake>): Promise<number> => { return this.api.oEMMake.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of OEMMakes matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMMake>): Promise<Record<string, number>> => { return this.api.oEMMake.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMMakes matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMMake>): Promise<number | null | undefined> => { return this.api.oEMMake.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMMakes matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMMake>): Promise<Record<string, number | null | undefined>> => { return this.api.oEMMake.groupAggregate(input, this.userToken) }, 
    }
    readonly oEMModel = {
        
        /**
        * Gets a default OEMModel that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<OEMModel> => { return this.api.oEMModel.default(this.userToken) }, 
        
        /**
        * Gets a list of OEMModels that match the given query.
        **/
        query: (input: Query<OEMModel>): Promise<Array<OEMModel>> => { return this.api.oEMModel.query(input, this.userToken) }, 
        
        /**
        * Gets parts of OEMModels that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMModel>): Promise<Array<DeepPartial<OEMModel>>> => { return this.api.oEMModel.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single OEMModel by ID.
        **/
        detail: (id: string): Promise<OEMModel> => { return this.api.oEMModel.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple OEMModels at the same time.
        **/
        insertBulk: (input: Array<OEMModel>): Promise<Array<OEMModel>> => { return this.api.oEMModel.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new OEMModel
        **/
        insert: (input: OEMModel): Promise<OEMModel> => { return this.api.oEMModel.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a OEMModel
        **/
        upsert: (id: string, input: OEMModel): Promise<OEMModel> => { return this.api.oEMModel.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMModels at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMModel>): Promise<Array<OEMModel>> => { return this.api.oEMModel.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single OEMModel by ID.
        **/
        replace: (id: string, input: OEMModel): Promise<OEMModel> => { return this.api.oEMModel.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMModels at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMModel>): Promise<number> => { return this.api.oEMModel.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a OEMModel by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<OEMModel>): Promise<EntryChange<OEMModel>> => { return this.api.oEMModel.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a OEMModel by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<OEMModel>): Promise<OEMModel> => { return this.api.oEMModel.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching OEMModels, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMModel>): Promise<number> => { return this.api.oEMModel.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a OEMModel by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.oEMModel.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of OEMModels matching the given condition.
        **/
        count: (input: Condition<OEMModel>): Promise<number> => { return this.api.oEMModel.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of OEMModels matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMModel>): Promise<Record<string, number>> => { return this.api.oEMModel.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMModels matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMModel>): Promise<number | null | undefined> => { return this.api.oEMModel.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMModels matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMModel>): Promise<Record<string, number | null | undefined>> => { return this.api.oEMModel.groupAggregate(input, this.userToken) }, 
    }
    readonly oEMVehicle = {
        
        /**
        * Gets a default OEMVehicle that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<OEMVehicle> => { return this.api.oEMVehicle.default(this.userToken) }, 
        
        /**
        * Gets a list of OEMVehicles that match the given query.
        **/
        query: (input: Query<OEMVehicle>): Promise<Array<OEMVehicle>> => { return this.api.oEMVehicle.query(input, this.userToken) }, 
        
        /**
        * Gets parts of OEMVehicles that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMVehicle>): Promise<Array<DeepPartial<OEMVehicle>>> => { return this.api.oEMVehicle.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single OEMVehicle by ID.
        **/
        detail: (id: UUID): Promise<OEMVehicle> => { return this.api.oEMVehicle.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple OEMVehicles at the same time.
        **/
        insertBulk: (input: Array<OEMVehicle>): Promise<Array<OEMVehicle>> => { return this.api.oEMVehicle.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new OEMVehicle
        **/
        insert: (input: OEMVehicle): Promise<OEMVehicle> => { return this.api.oEMVehicle.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a OEMVehicle
        **/
        upsert: (id: UUID, input: OEMVehicle): Promise<OEMVehicle> => { return this.api.oEMVehicle.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMVehicles at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMVehicle>): Promise<Array<OEMVehicle>> => { return this.api.oEMVehicle.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single OEMVehicle by ID.
        **/
        replace: (id: UUID, input: OEMVehicle): Promise<OEMVehicle> => { return this.api.oEMVehicle.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMVehicles at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMVehicle>): Promise<number> => { return this.api.oEMVehicle.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a OEMVehicle by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<OEMVehicle>): Promise<EntryChange<OEMVehicle>> => { return this.api.oEMVehicle.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a OEMVehicle by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<OEMVehicle>): Promise<OEMVehicle> => { return this.api.oEMVehicle.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching OEMVehicles, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMVehicle>): Promise<number> => { return this.api.oEMVehicle.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a OEMVehicle by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.oEMVehicle.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of OEMVehicles matching the given condition.
        **/
        count: (input: Condition<OEMVehicle>): Promise<number> => { return this.api.oEMVehicle.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of OEMVehicles matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMVehicle>): Promise<Record<string, number>> => { return this.api.oEMVehicle.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMVehicles matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMVehicle>): Promise<number | null | undefined> => { return this.api.oEMVehicle.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMVehicles matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMVehicle>): Promise<Record<string, number | null | undefined>> => { return this.api.oEMVehicle.groupAggregate(input, this.userToken) }, 
    }
    readonly oEMYear = {
        
        /**
        * Gets a default OEMYear that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<OEMYear> => { return this.api.oEMYear.default(this.userToken) }, 
        
        /**
        * Gets a list of OEMYears that match the given query.
        **/
        query: (input: Query<OEMYear>): Promise<Array<OEMYear>> => { return this.api.oEMYear.query(input, this.userToken) }, 
        
        /**
        * Gets parts of OEMYears that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMYear>): Promise<Array<DeepPartial<OEMYear>>> => { return this.api.oEMYear.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single OEMYear by ID.
        **/
        detail: (id: UUID): Promise<OEMYear> => { return this.api.oEMYear.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple OEMYears at the same time.
        **/
        insertBulk: (input: Array<OEMYear>): Promise<Array<OEMYear>> => { return this.api.oEMYear.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new OEMYear
        **/
        insert: (input: OEMYear): Promise<OEMYear> => { return this.api.oEMYear.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a OEMYear
        **/
        upsert: (id: UUID, input: OEMYear): Promise<OEMYear> => { return this.api.oEMYear.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMYears at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMYear>): Promise<Array<OEMYear>> => { return this.api.oEMYear.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single OEMYear by ID.
        **/
        replace: (id: UUID, input: OEMYear): Promise<OEMYear> => { return this.api.oEMYear.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many OEMYears at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMYear>): Promise<number> => { return this.api.oEMYear.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a OEMYear by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<OEMYear>): Promise<EntryChange<OEMYear>> => { return this.api.oEMYear.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a OEMYear by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<OEMYear>): Promise<OEMYear> => { return this.api.oEMYear.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching OEMYears, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMYear>): Promise<number> => { return this.api.oEMYear.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a OEMYear by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.oEMYear.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of OEMYears matching the given condition.
        **/
        count: (input: Condition<OEMYear>): Promise<number> => { return this.api.oEMYear.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of OEMYears matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMYear>): Promise<Record<string, number>> => { return this.api.oEMYear.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMYears matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMYear>): Promise<number | null | undefined> => { return this.api.oEMYear.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of OEMYears matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMYear>): Promise<Record<string, number | null | undefined>> => { return this.api.oEMYear.groupAggregate(input, this.userToken) }, 
    }
    readonly order = {
        
        /**
        * Gets a default Order that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<Order> => { return this.api.order.default(this.userToken) }, 
        
        /**
        * Gets a list of Orders that match the given query.
        **/
        query: (input: Query<Order>): Promise<Array<Order>> => { return this.api.order.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Orders that match the given query.
        **/
        queryPartial: (input: QueryPartial<Order>): Promise<Array<DeepPartial<Order>>> => { return this.api.order.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single Order by ID.
        **/
        detail: (id: UUID): Promise<Order> => { return this.api.order.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Orders at the same time.
        **/
        insertBulk: (input: Array<Order>): Promise<Array<Order>> => { return this.api.order.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new Order
        **/
        insert: (input: Order): Promise<Order> => { return this.api.order.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a Order
        **/
        upsert: (id: UUID, input: Order): Promise<Order> => { return this.api.order.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Orders at the same time by ID.
        **/
        bulkReplace: (input: Array<Order>): Promise<Array<Order>> => { return this.api.order.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single Order by ID.
        **/
        replace: (id: UUID, input: Order): Promise<Order> => { return this.api.order.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Orders at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Order>): Promise<number> => { return this.api.order.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a Order by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<Order>): Promise<EntryChange<Order>> => { return this.api.order.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a Order by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<Order>): Promise<Order> => { return this.api.order.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Orders, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Order>): Promise<number> => { return this.api.order.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a Order by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.order.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Orders matching the given condition.
        **/
        count: (input: Condition<Order>): Promise<number> => { return this.api.order.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Orders matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Order>): Promise<Record<string, number>> => { return this.api.order.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Orders matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Order>): Promise<number | null | undefined> => { return this.api.order.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Orders matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Order>): Promise<Record<string, number | null | undefined>> => { return this.api.order.groupAggregate(input, this.userToken) }, 
    }
    readonly payment = {
        
        /**
        * Gets a default PaymentRequest that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<ExternalAsyncTaskRequest> => { return this.api.payment.default(this.userToken) }, 
        
        /**
        * Gets a list of PaymentRequests that match the given query.
        **/
        query: (input: Query<ExternalAsyncTaskRequest>): Promise<Array<ExternalAsyncTaskRequest>> => { return this.api.payment.query(input, this.userToken) }, 
        
        /**
        * Gets parts of PaymentRequests that match the given query.
        **/
        queryPartial: (input: QueryPartial<ExternalAsyncTaskRequest>): Promise<Array<DeepPartial<ExternalAsyncTaskRequest>>> => { return this.api.payment.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single PaymentRequest by ID.
        **/
        detail: (id: string): Promise<ExternalAsyncTaskRequest> => { return this.api.payment.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple PaymentRequests at the same time.
        **/
        insertBulk: (input: Array<ExternalAsyncTaskRequest>): Promise<Array<ExternalAsyncTaskRequest>> => { return this.api.payment.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new PaymentRequest
        **/
        insert: (input: ExternalAsyncTaskRequest): Promise<ExternalAsyncTaskRequest> => { return this.api.payment.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a PaymentRequest
        **/
        upsert: (id: string, input: ExternalAsyncTaskRequest): Promise<ExternalAsyncTaskRequest> => { return this.api.payment.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many PaymentRequests at the same time by ID.
        **/
        bulkReplace: (input: Array<ExternalAsyncTaskRequest>): Promise<Array<ExternalAsyncTaskRequest>> => { return this.api.payment.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single PaymentRequest by ID.
        **/
        replace: (id: string, input: ExternalAsyncTaskRequest): Promise<ExternalAsyncTaskRequest> => { return this.api.payment.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many PaymentRequests at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ExternalAsyncTaskRequest>): Promise<number> => { return this.api.payment.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a PaymentRequest by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ExternalAsyncTaskRequest>): Promise<EntryChange<ExternalAsyncTaskRequest>> => { return this.api.payment.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a PaymentRequest by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ExternalAsyncTaskRequest>): Promise<ExternalAsyncTaskRequest> => { return this.api.payment.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching PaymentRequests, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ExternalAsyncTaskRequest>): Promise<number> => { return this.api.payment.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a PaymentRequest by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.payment.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of PaymentRequests matching the given condition.
        **/
        count: (input: Condition<ExternalAsyncTaskRequest>): Promise<number> => { return this.api.payment.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of PaymentRequests matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ExternalAsyncTaskRequest>): Promise<Record<string, number>> => { return this.api.payment.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of PaymentRequests matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ExternalAsyncTaskRequest>): Promise<number | null | undefined> => { return this.api.payment.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of PaymentRequests matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ExternalAsyncTaskRequest>): Promise<Record<string, number | null | undefined>> => { return this.api.payment.groupAggregate(input, this.userToken) }, 
        
        /**
        * Manually recheck tasks
        **/
        manuallyRecheckTasks: (): Promise<void> => { return this.api.payment.manuallyRecheckTasks(this.userToken) }, 
        
        /**
        * Manually recheck a task
        **/
        manuallyRecheckATask: (id: string): Promise<void> => { return this.api.payment.manuallyRecheckATask(id, this.userToken) }, 
    }
    readonly product = {
        
        /**
        * Gets a default Product that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<Product> => { return this.api.product.default(this.userToken) }, 
        
        /**
        * Gets a list of Products that match the given query.
        **/
        query: (input: Query<Product>): Promise<Array<Product>> => { return this.api.product.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Products that match the given query.
        **/
        queryPartial: (input: QueryPartial<Product>): Promise<Array<DeepPartial<Product>>> => { return this.api.product.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single Product by ID.
        **/
        detail: (id: string): Promise<Product> => { return this.api.product.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Products at the same time.
        **/
        insertBulk: (input: Array<Product>): Promise<Array<Product>> => { return this.api.product.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new Product
        **/
        insert: (input: Product): Promise<Product> => { return this.api.product.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a Product
        **/
        upsert: (id: string, input: Product): Promise<Product> => { return this.api.product.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Products at the same time by ID.
        **/
        bulkReplace: (input: Array<Product>): Promise<Array<Product>> => { return this.api.product.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single Product by ID.
        **/
        replace: (id: string, input: Product): Promise<Product> => { return this.api.product.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Products at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Product>): Promise<number> => { return this.api.product.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a Product by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<Product>): Promise<EntryChange<Product>> => { return this.api.product.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a Product by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<Product>): Promise<Product> => { return this.api.product.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Products, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Product>): Promise<number> => { return this.api.product.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a Product by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.product.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Products matching the given condition.
        **/
        count: (input: Condition<Product>): Promise<number> => { return this.api.product.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Products matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Product>): Promise<Record<string, number>> => { return this.api.product.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Products matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Product>): Promise<number | null | undefined> => { return this.api.product.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Products matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Product>): Promise<Record<string, number | null | undefined>> => { return this.api.product.groupAggregate(input, this.userToken) }, 
        
        /**
        * Returns a minimized model for page building purposes
        **/
        minimal: (input: Query<Product>): Promise<Array<ProductMinimized>> => { return this.api.product.minimal(input, this.userToken) }, 
    }
    readonly productTag = {
        
        /**
        * Gets a default ProductTag that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<ProductTag> => { return this.api.productTag.default(this.userToken) }, 
        
        /**
        * Gets a list of ProductTags that match the given query.
        **/
        query: (input: Query<ProductTag>): Promise<Array<ProductTag>> => { return this.api.productTag.query(input, this.userToken) }, 
        
        /**
        * Gets parts of ProductTags that match the given query.
        **/
        queryPartial: (input: QueryPartial<ProductTag>): Promise<Array<DeepPartial<ProductTag>>> => { return this.api.productTag.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single ProductTag by ID.
        **/
        detail: (id: string): Promise<ProductTag> => { return this.api.productTag.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple ProductTags at the same time.
        **/
        insertBulk: (input: Array<ProductTag>): Promise<Array<ProductTag>> => { return this.api.productTag.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new ProductTag
        **/
        insert: (input: ProductTag): Promise<ProductTag> => { return this.api.productTag.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a ProductTag
        **/
        upsert: (id: string, input: ProductTag): Promise<ProductTag> => { return this.api.productTag.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many ProductTags at the same time by ID.
        **/
        bulkReplace: (input: Array<ProductTag>): Promise<Array<ProductTag>> => { return this.api.productTag.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single ProductTag by ID.
        **/
        replace: (id: string, input: ProductTag): Promise<ProductTag> => { return this.api.productTag.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many ProductTags at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ProductTag>): Promise<number> => { return this.api.productTag.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a ProductTag by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ProductTag>): Promise<EntryChange<ProductTag>> => { return this.api.productTag.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a ProductTag by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ProductTag>): Promise<ProductTag> => { return this.api.productTag.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching ProductTags, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ProductTag>): Promise<number> => { return this.api.productTag.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a ProductTag by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.productTag.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of ProductTags matching the given condition.
        **/
        count: (input: Condition<ProductTag>): Promise<number> => { return this.api.productTag.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of ProductTags matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ProductTag>): Promise<Record<string, number>> => { return this.api.productTag.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ProductTags matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ProductTag>): Promise<number | null | undefined> => { return this.api.productTag.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ProductTags matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ProductTag>): Promise<Record<string, number | null | undefined>> => { return this.api.productTag.groupAggregate(input, this.userToken) }, 
    }
    readonly productVariant = {
        
        /**
        * Gets a default ProductVariant that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<ProductVariant> => { return this.api.productVariant.default(this.userToken) }, 
        
        /**
        * Gets a list of ProductVariants that match the given query.
        **/
        query: (input: Query<ProductVariant>): Promise<Array<ProductVariant>> => { return this.api.productVariant.query(input, this.userToken) }, 
        
        /**
        * Gets parts of ProductVariants that match the given query.
        **/
        queryPartial: (input: QueryPartial<ProductVariant>): Promise<Array<DeepPartial<ProductVariant>>> => { return this.api.productVariant.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single ProductVariant by ID.
        **/
        detail: (id: UUID): Promise<ProductVariant> => { return this.api.productVariant.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple ProductVariants at the same time.
        **/
        insertBulk: (input: Array<ProductVariant>): Promise<Array<ProductVariant>> => { return this.api.productVariant.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new ProductVariant
        **/
        insert: (input: ProductVariant): Promise<ProductVariant> => { return this.api.productVariant.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a ProductVariant
        **/
        upsert: (id: UUID, input: ProductVariant): Promise<ProductVariant> => { return this.api.productVariant.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many ProductVariants at the same time by ID.
        **/
        bulkReplace: (input: Array<ProductVariant>): Promise<Array<ProductVariant>> => { return this.api.productVariant.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single ProductVariant by ID.
        **/
        replace: (id: UUID, input: ProductVariant): Promise<ProductVariant> => { return this.api.productVariant.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many ProductVariants at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ProductVariant>): Promise<number> => { return this.api.productVariant.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a ProductVariant by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<ProductVariant>): Promise<EntryChange<ProductVariant>> => { return this.api.productVariant.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a ProductVariant by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<ProductVariant>): Promise<ProductVariant> => { return this.api.productVariant.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching ProductVariants, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ProductVariant>): Promise<number> => { return this.api.productVariant.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a ProductVariant by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.productVariant.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of ProductVariants matching the given condition.
        **/
        count: (input: Condition<ProductVariant>): Promise<number> => { return this.api.productVariant.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of ProductVariants matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ProductVariant>): Promise<Record<string, number>> => { return this.api.productVariant.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ProductVariants matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ProductVariant>): Promise<number | null | undefined> => { return this.api.productVariant.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ProductVariants matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ProductVariant>): Promise<Record<string, number | null | undefined>> => { return this.api.productVariant.groupAggregate(input, this.userToken) }, 
    }
    readonly review = {
        
        /**
        * Gets a default Review that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<Review> => { return this.api.review.default(this.userToken) }, 
        
        /**
        * Gets a list of Reviews that match the given query.
        **/
        query: (input: Query<Review>): Promise<Array<Review>> => { return this.api.review.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Reviews that match the given query.
        **/
        queryPartial: (input: QueryPartial<Review>): Promise<Array<DeepPartial<Review>>> => { return this.api.review.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single Review by ID.
        **/
        detail: (id: UUID): Promise<Review> => { return this.api.review.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Reviews at the same time.
        **/
        insertBulk: (input: Array<Review>): Promise<Array<Review>> => { return this.api.review.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new Review
        **/
        insert: (input: Review): Promise<Review> => { return this.api.review.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a Review
        **/
        upsert: (id: UUID, input: Review): Promise<Review> => { return this.api.review.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Reviews at the same time by ID.
        **/
        bulkReplace: (input: Array<Review>): Promise<Array<Review>> => { return this.api.review.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single Review by ID.
        **/
        replace: (id: UUID, input: Review): Promise<Review> => { return this.api.review.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Reviews at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Review>): Promise<number> => { return this.api.review.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a Review by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<Review>): Promise<EntryChange<Review>> => { return this.api.review.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a Review by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<Review>): Promise<Review> => { return this.api.review.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Reviews, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Review>): Promise<number> => { return this.api.review.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a Review by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.review.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Reviews matching the given condition.
        **/
        count: (input: Condition<Review>): Promise<number> => { return this.api.review.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Reviews matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Review>): Promise<Record<string, number>> => { return this.api.review.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Reviews matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Review>): Promise<number | null | undefined> => { return this.api.review.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Reviews matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Review>): Promise<Record<string, number | null | undefined>> => { return this.api.review.groupAggregate(input, this.userToken) }, 
    }
    readonly sale = {
        
        /**
        * Gets a default Sale that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<Sale> => { return this.api.sale.default(this.userToken) }, 
        
        /**
        * Gets a list of Sales that match the given query.
        **/
        query: (input: Query<Sale>): Promise<Array<Sale>> => { return this.api.sale.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Sales that match the given query.
        **/
        queryPartial: (input: QueryPartial<Sale>): Promise<Array<DeepPartial<Sale>>> => { return this.api.sale.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single Sale by ID.
        **/
        detail: (id: string): Promise<Sale> => { return this.api.sale.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Sales at the same time.
        **/
        insertBulk: (input: Array<Sale>): Promise<Array<Sale>> => { return this.api.sale.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new Sale
        **/
        insert: (input: Sale): Promise<Sale> => { return this.api.sale.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a Sale
        **/
        upsert: (id: string, input: Sale): Promise<Sale> => { return this.api.sale.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Sales at the same time by ID.
        **/
        bulkReplace: (input: Array<Sale>): Promise<Array<Sale>> => { return this.api.sale.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single Sale by ID.
        **/
        replace: (id: string, input: Sale): Promise<Sale> => { return this.api.sale.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Sales at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Sale>): Promise<number> => { return this.api.sale.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a Sale by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<Sale>): Promise<EntryChange<Sale>> => { return this.api.sale.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a Sale by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<Sale>): Promise<Sale> => { return this.api.sale.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Sales, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Sale>): Promise<number> => { return this.api.sale.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a Sale by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.sale.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Sales matching the given condition.
        **/
        count: (input: Condition<Sale>): Promise<number> => { return this.api.sale.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Sales matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Sale>): Promise<Record<string, number>> => { return this.api.sale.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Sales matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Sale>): Promise<number | null | undefined> => { return this.api.sale.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Sales matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Sale>): Promise<Record<string, number | null | undefined>> => { return this.api.sale.groupAggregate(input, this.userToken) }, 
    }
    readonly shopTalk = {
        
        /**
        * Gets a default ShopTalk that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<ShopTalk> => { return this.api.shopTalk.default(this.userToken) }, 
        
        /**
        * Gets a list of ShopTalks that match the given query.
        **/
        query: (input: Query<ShopTalk>): Promise<Array<ShopTalk>> => { return this.api.shopTalk.query(input, this.userToken) }, 
        
        /**
        * Gets parts of ShopTalks that match the given query.
        **/
        queryPartial: (input: QueryPartial<ShopTalk>): Promise<Array<DeepPartial<ShopTalk>>> => { return this.api.shopTalk.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single ShopTalk by ID.
        **/
        detail: (id: UUID): Promise<ShopTalk> => { return this.api.shopTalk.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple ShopTalks at the same time.
        **/
        insertBulk: (input: Array<ShopTalk>): Promise<Array<ShopTalk>> => { return this.api.shopTalk.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new ShopTalk
        **/
        insert: (input: ShopTalk): Promise<ShopTalk> => { return this.api.shopTalk.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a ShopTalk
        **/
        upsert: (id: UUID, input: ShopTalk): Promise<ShopTalk> => { return this.api.shopTalk.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many ShopTalks at the same time by ID.
        **/
        bulkReplace: (input: Array<ShopTalk>): Promise<Array<ShopTalk>> => { return this.api.shopTalk.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single ShopTalk by ID.
        **/
        replace: (id: UUID, input: ShopTalk): Promise<ShopTalk> => { return this.api.shopTalk.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many ShopTalks at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ShopTalk>): Promise<number> => { return this.api.shopTalk.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a ShopTalk by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<ShopTalk>): Promise<EntryChange<ShopTalk>> => { return this.api.shopTalk.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a ShopTalk by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<ShopTalk>): Promise<ShopTalk> => { return this.api.shopTalk.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching ShopTalks, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ShopTalk>): Promise<number> => { return this.api.shopTalk.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a ShopTalk by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.shopTalk.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of ShopTalks matching the given condition.
        **/
        count: (input: Condition<ShopTalk>): Promise<number> => { return this.api.shopTalk.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of ShopTalks matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ShopTalk>): Promise<Record<string, number>> => { return this.api.shopTalk.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ShopTalks matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ShopTalk>): Promise<number | null | undefined> => { return this.api.shopTalk.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of ShopTalks matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ShopTalk>): Promise<Record<string, number | null | undefined>> => { return this.api.shopTalk.groupAggregate(input, this.userToken) }, 
    }
    readonly syncProgress = {
        
        /**
        * Gets a default SyncProgress that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<SyncProgress> => { return this.api.syncProgress.default(this.userToken) }, 
        
        /**
        * Gets a list of SyncProgresss that match the given query.
        **/
        query: (input: Query<SyncProgress>): Promise<Array<SyncProgress>> => { return this.api.syncProgress.query(input, this.userToken) }, 
        
        /**
        * Gets parts of SyncProgresss that match the given query.
        **/
        queryPartial: (input: QueryPartial<SyncProgress>): Promise<Array<DeepPartial<SyncProgress>>> => { return this.api.syncProgress.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single SyncProgress by ID.
        **/
        detail: (id: string): Promise<SyncProgress> => { return this.api.syncProgress.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple SyncProgresss at the same time.
        **/
        insertBulk: (input: Array<SyncProgress>): Promise<Array<SyncProgress>> => { return this.api.syncProgress.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new SyncProgress
        **/
        insert: (input: SyncProgress): Promise<SyncProgress> => { return this.api.syncProgress.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a SyncProgress
        **/
        upsert: (id: string, input: SyncProgress): Promise<SyncProgress> => { return this.api.syncProgress.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many SyncProgresss at the same time by ID.
        **/
        bulkReplace: (input: Array<SyncProgress>): Promise<Array<SyncProgress>> => { return this.api.syncProgress.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single SyncProgress by ID.
        **/
        replace: (id: string, input: SyncProgress): Promise<SyncProgress> => { return this.api.syncProgress.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many SyncProgresss at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<SyncProgress>): Promise<number> => { return this.api.syncProgress.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a SyncProgress by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<SyncProgress>): Promise<EntryChange<SyncProgress>> => { return this.api.syncProgress.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a SyncProgress by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<SyncProgress>): Promise<SyncProgress> => { return this.api.syncProgress.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching SyncProgresss, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<SyncProgress>): Promise<number> => { return this.api.syncProgress.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a SyncProgress by id.
        **/
        delete: (id: string): Promise<void> => { return this.api.syncProgress.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of SyncProgresss matching the given condition.
        **/
        count: (input: Condition<SyncProgress>): Promise<number> => { return this.api.syncProgress.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of SyncProgresss matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<SyncProgress>): Promise<Record<string, number>> => { return this.api.syncProgress.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of SyncProgresss matching the given condition.
        **/
        aggregate: (input: AggregateQuery<SyncProgress>): Promise<number | null | undefined> => { return this.api.syncProgress.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of SyncProgresss matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<SyncProgress>): Promise<Record<string, number | null | undefined>> => { return this.api.syncProgress.groupAggregate(input, this.userToken) }, 
    }
    readonly user = {
        
        /**
        * Gets a default User that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (): Promise<User> => { return this.api.user.default(this.userToken) }, 
        
        /**
        * Gets a list of Users that match the given query.
        **/
        query: (input: Query<User>): Promise<Array<User>> => { return this.api.user.query(input, this.userToken) }, 
        
        /**
        * Gets parts of Users that match the given query.
        **/
        queryPartial: (input: QueryPartial<User>): Promise<Array<DeepPartial<User>>> => { return this.api.user.queryPartial(input, this.userToken) }, 
        
        /**
        * Gets a single User by ID.
        **/
        detail: (id: UUID): Promise<User> => { return this.api.user.detail(id, this.userToken) }, 
        
        /**
        * Creates multiple Users at the same time.
        **/
        insertBulk: (input: Array<User>): Promise<Array<User>> => { return this.api.user.insertBulk(input, this.userToken) }, 
        
        /**
        * Creates a new User
        **/
        insert: (input: User): Promise<User> => { return this.api.user.insert(input, this.userToken) }, 
        
        /**
        * Creates or updates a User
        **/
        upsert: (id: UUID, input: User): Promise<User> => { return this.api.user.upsert(id, input, this.userToken) }, 
        
        /**
        * Modifies many Users at the same time by ID.
        **/
        bulkReplace: (input: Array<User>): Promise<Array<User>> => { return this.api.user.bulkReplace(input, this.userToken) }, 
        
        /**
        * Replaces a single User by ID.
        **/
        replace: (id: UUID, input: User): Promise<User> => { return this.api.user.replace(id, input, this.userToken) }, 
        
        /**
        * Modifies many Users at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<User>): Promise<number> => { return this.api.user.bulkModify(input, this.userToken) }, 
        
        /**
        * Modifies a User by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<User>): Promise<EntryChange<User>> => { return this.api.user.modifyWithDiff(id, input, this.userToken) }, 
        
        /**
        * Modifies a User by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<User>): Promise<User> => { return this.api.user.modify(id, input, this.userToken) }, 
        
        /**
        * Deletes all matching Users, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<User>): Promise<number> => { return this.api.user.bulkDelete(input, this.userToken) }, 
        
        /**
        * Deletes a User by id.
        **/
        delete: (id: UUID): Promise<void> => { return this.api.user.delete(id, this.userToken) }, 
        
        /**
        * Gets the total number of Users matching the given condition.
        **/
        count: (input: Condition<User>): Promise<number> => { return this.api.user.count(input, this.userToken) }, 
        
        /**
        * Gets the total number of Users matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<User>): Promise<Record<string, number>> => { return this.api.user.groupCount(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Users matching the given condition.
        **/
        aggregate: (input: AggregateQuery<User>): Promise<number | null | undefined> => { return this.api.user.aggregate(input, this.userToken) }, 
        
        /**
        * Aggregates a property of Users matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<User>): Promise<Record<string, number | null | undefined>> => { return this.api.user.groupAggregate(input, this.userToken) }, 
    }
}




export class LiveApi implements Api {
    public constructor(public httpUrl: string, public socketUrl: string = httpUrl, public extraHeaders: Record<string, string> = {}, public responseInterceptors?: (x: Response)=>Response) {}
    
    /**
    * Begin Sync
    **/
    beginSync(input: SyncRequest, userToken: string): Promise<SyncProgress | null | undefined> {
        return apiCall<SyncRequest>(
            `${this.httpUrl}/sync`,
            input,
            {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Begin Sync Temecula
    **/
    beginSyncTemecula(input: SyncRequest, userToken: string): Promise<void> {
        return apiCall<SyncRequest>(
            `${this.httpUrl}/sync-temecula`,
            input,
            {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => undefined)
    }
    
    /**
    * Returns the pending order tied to the Stripe Payment Intent Client Secret
    **/
    getPendingOrder(clientSecret: string, userToken?: string): Promise<PendingOrder> {
        return apiCall<void>(
            `${this.httpUrl}/pending-orders/${clientSecret}`,
            undefined,
            {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Gets the current status of the server
    **/
    getServerHealth(userToken: string): Promise<ServerHealth> {
        return apiCall<void>(
            `${this.httpUrl}/meta/health`,
            undefined,
            {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Get Version
    **/
    getVersion(): Promise<string> {
        return apiCall<void>(
            `${this.httpUrl}/version`,
            undefined,
            {
                method: "GET",
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Returns a url for a csv with all product data int the Google Merchant Listing format.
    **/
    merchantListings(userToken: string): Promise<string> {
        return apiCall<void>(
            `${this.httpUrl}/merchant-listings`,
            undefined,
            {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Pick a random user for a giveaway
    **/
    pickARandomUserForAGiveaway(userToken: string): Promise<User> {
        return apiCall<void>(
            `${this.httpUrl}/users/giveaway-pick`,
            undefined,
            {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Place Order
    **/
    placeOrder(input: LegacyOrderRequest, userToken?: string): Promise<LegacyPaymentResponse> {
        return apiCall<LegacyOrderRequest>(
            `${this.httpUrl}/orders/place-order`,
            input,
            {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Place Order v2
    **/
    placeOrderV2(input: StartOrderRequest, userToken?: string): Promise<string> {
        return apiCall<StartOrderRequest>(
            `${this.httpUrl}/orders/place-order-v2`,
            input,
            {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Adds the fitment provided to the relevant products.
    **/
    submitFitment(brandId: string, input: Array<Fitment>, userToken: string): Promise<void> {
        return apiCall<Array<Fitment>>(
            `${this.httpUrl}/products/${brandId}/submit-fitment`,
            input,
            {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => undefined)
    }
    
    /**
    * Adds the fitment provided to the relevant products.
    **/
    submitFitmentYearRange(brandId: string, input: Array<FitmentYearRange>, userToken: string): Promise<void> {
        return apiCall<Array<FitmentYearRange>>(
            `${this.httpUrl}/products/${brandId}/submit-fitment-year-range`,
            input,
            {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => undefined)
    }
    
    /**
    * Brings over all the categories from the old woocommerce site
    **/
    syncWoocommerceCategories(userToken: string): Promise<void> {
        return apiCall<void>(
            `${this.httpUrl}/sync/woocommerce/categories`,
            undefined,
            {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => undefined)
    }
    
    /**
    * Brings over all the categories for each product from the old woocommerce site
    **/
    syncWoocommerceProductCategories(input: SyncRequest, userToken: string): Promise<SyncProgress> {
        return apiCall<SyncRequest>(
            `${this.httpUrl}/sync/woocommerce/product-categories`,
            input,
            {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    
    /**
    * Upload a file to make a request later.  Times out in around 10 minutes.
    **/
    uploadFileForRequest(): Promise<UploadInformation> {
        return apiCall<void>(
            `${this.httpUrl}/upload-early`,
            undefined,
            {
                method: "GET",
            }, 
            undefined,
            this.responseInterceptors, 
        ).then(x => x.json())
    }
    readonly auth = {
        
        /**
        * Creates a new token for the user, which can be used to authenticate with the API via the header 'Authorization: Bearer [insert token here]'.
        **/
        refreshToken: (userToken: string): Promise<string> => {
            return apiCall<void>(
                `${this.httpUrl}/auth/refresh-token`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Retrieves the user that you currently authenticated as.
        **/
        getSelf: (userToken: string): Promise<User> => {
            return apiCall<void>(
                `${this.httpUrl}/auth/self`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a token for a new, anonymous user.  The token can be used to authenticate with the API via the header 'Authorization: Bearer [insert token here]
        **/
        anonymousToken: (userToken?: string): Promise<string> => {
            return apiCall<void>(
                `${this.httpUrl}/auth/anonymous`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Sends a login email to the given address.  The email will contain both a link to instantly log in and a PIN that can be entered to log in.
        **/
        emailLoginLink: (input: string): Promise<void> => {
            return apiCall<string>(
                `${this.httpUrl}/auth/login-email`,
                input,
                {
                    method: "POST",
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Logs in to the given account with a PIN that was provided in an email sent earlier.  Note that the PIN expires in 15 minutes, and you are only permitted 5 attempts.
        **/
        emailPINLogin: (input: EmailPinLogin): Promise<string> => {
            return apiCall<EmailPinLogin>(
                `${this.httpUrl}/auth/login-email-pin`,
                input,
                {
                    method: "POST",
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly brand = {
        
        /**
        * Gets a default Brand that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<Brand> => {
            return apiCall<void>(
                `${this.httpUrl}/brands/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Brands that match the given query.
        **/
        query: (input: Query<Brand>, userToken?: string): Promise<Array<Brand>> => {
            return apiCall<Query<Brand>>(
                `${this.httpUrl}/brands/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Brands that match the given query.
        **/
        queryPartial: (input: QueryPartial<Brand>, userToken?: string): Promise<Array<DeepPartial<Brand>>> => {
            return apiCall<QueryPartial<Brand>>(
                `${this.httpUrl}/brands/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single Brand by ID.
        **/
        detail: (id: string, userToken?: string): Promise<Brand> => {
            return apiCall<void>(
                `${this.httpUrl}/brands/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Brands at the same time.
        **/
        insertBulk: (input: Array<Brand>, userToken?: string): Promise<Array<Brand>> => {
            return apiCall<Array<Brand>>(
                `${this.httpUrl}/brands/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new Brand
        **/
        insert: (input: Brand, userToken?: string): Promise<Brand> => {
            return apiCall<Brand>(
                `${this.httpUrl}/brands/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a Brand
        **/
        upsert: (id: string, input: Brand, userToken?: string): Promise<Brand> => {
            return apiCall<Brand>(
                `${this.httpUrl}/brands/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Brands at the same time by ID.
        **/
        bulkReplace: (input: Array<Brand>, userToken?: string): Promise<Array<Brand>> => {
            return apiCall<Array<Brand>>(
                `${this.httpUrl}/brands/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single Brand by ID.
        **/
        replace: (id: string, input: Brand, userToken?: string): Promise<Brand> => {
            return apiCall<Brand>(
                `${this.httpUrl}/brands/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Brands at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Brand>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<Brand>>(
                `${this.httpUrl}/brands/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Brand by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<Brand>, userToken?: string): Promise<EntryChange<Brand>> => {
            return apiCall<Modification<Brand>>(
                `${this.httpUrl}/brands/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Brand by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<Brand>, userToken?: string): Promise<Brand> => {
            return apiCall<Modification<Brand>>(
                `${this.httpUrl}/brands/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Brands, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Brand>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Brand>>(
                `${this.httpUrl}/brands/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a Brand by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/brands/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Brands matching the given condition.
        **/
        count: (input: Condition<Brand>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Brand>>(
                `${this.httpUrl}/brands/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Brands matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Brand>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<Brand>>(
                `${this.httpUrl}/brands/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Brands matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Brand>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<Brand>>(
                `${this.httpUrl}/brands/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Brands matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Brand>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<Brand>>(
                `${this.httpUrl}/brands/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly changeItem = {
        
        /**
        * Gets a default ChangeItem that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<ChangeItem> => {
            return apiCall<void>(
                `${this.httpUrl}/changes/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of ChangeItems that match the given query.
        **/
        query: (input: Query<ChangeItem>, userToken?: string): Promise<Array<ChangeItem>> => {
            return apiCall<Query<ChangeItem>>(
                `${this.httpUrl}/changes/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of ChangeItems that match the given query.
        **/
        queryPartial: (input: QueryPartial<ChangeItem>, userToken?: string): Promise<Array<DeepPartial<ChangeItem>>> => {
            return apiCall<QueryPartial<ChangeItem>>(
                `${this.httpUrl}/changes/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single ChangeItem by ID.
        **/
        detail: (id: string, userToken?: string): Promise<ChangeItem> => {
            return apiCall<void>(
                `${this.httpUrl}/changes/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple ChangeItems at the same time.
        **/
        insertBulk: (input: Array<ChangeItem>, userToken?: string): Promise<Array<ChangeItem>> => {
            return apiCall<Array<ChangeItem>>(
                `${this.httpUrl}/changes/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new ChangeItem
        **/
        insert: (input: ChangeItem, userToken?: string): Promise<ChangeItem> => {
            return apiCall<ChangeItem>(
                `${this.httpUrl}/changes/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a ChangeItem
        **/
        upsert: (id: string, input: ChangeItem, userToken?: string): Promise<ChangeItem> => {
            return apiCall<ChangeItem>(
                `${this.httpUrl}/changes/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ChangeItems at the same time by ID.
        **/
        bulkReplace: (input: Array<ChangeItem>, userToken?: string): Promise<Array<ChangeItem>> => {
            return apiCall<Array<ChangeItem>>(
                `${this.httpUrl}/changes/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single ChangeItem by ID.
        **/
        replace: (id: string, input: ChangeItem, userToken?: string): Promise<ChangeItem> => {
            return apiCall<ChangeItem>(
                `${this.httpUrl}/changes/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ChangeItems at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ChangeItem>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<ChangeItem>>(
                `${this.httpUrl}/changes/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ChangeItem by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ChangeItem>, userToken?: string): Promise<EntryChange<ChangeItem>> => {
            return apiCall<Modification<ChangeItem>>(
                `${this.httpUrl}/changes/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ChangeItem by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ChangeItem>, userToken?: string): Promise<ChangeItem> => {
            return apiCall<Modification<ChangeItem>>(
                `${this.httpUrl}/changes/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching ChangeItems, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ChangeItem>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ChangeItem>>(
                `${this.httpUrl}/changes/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a ChangeItem by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/changes/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of ChangeItems matching the given condition.
        **/
        count: (input: Condition<ChangeItem>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ChangeItem>>(
                `${this.httpUrl}/changes/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of ChangeItems matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ChangeItem>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<ChangeItem>>(
                `${this.httpUrl}/changes/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ChangeItems matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ChangeItem>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<ChangeItem>>(
                `${this.httpUrl}/changes/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ChangeItems matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ChangeItem>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<ChangeItem>>(
                `${this.httpUrl}/changes/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly dynamicSettings = {
        
        /**
        * Gets a default DynamicSettings that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken: string): Promise<DynamicSettings> => {
            return apiCall<void>(
                `${this.httpUrl}/dynamic-settings/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of DynamicSettingss that match the given query.
        **/
        query: (input: Query<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>> => {
            return apiCall<Query<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of DynamicSettingss that match the given query.
        **/
        queryPartial: (input: QueryPartial<DynamicSettings>, userToken: string): Promise<Array<DeepPartial<DynamicSettings>>> => {
            return apiCall<QueryPartial<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single DynamicSettings by ID.
        **/
        detail: (id: string, userToken: string): Promise<DynamicSettings> => {
            return apiCall<void>(
                `${this.httpUrl}/dynamic-settings/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple DynamicSettingss at the same time.
        **/
        insertBulk: (input: Array<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>> => {
            return apiCall<Array<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new DynamicSettings
        **/
        insert: (input: DynamicSettings, userToken: string): Promise<DynamicSettings> => {
            return apiCall<DynamicSettings>(
                `${this.httpUrl}/dynamic-settings/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a DynamicSettings
        **/
        upsert: (id: string, input: DynamicSettings, userToken: string): Promise<DynamicSettings> => {
            return apiCall<DynamicSettings>(
                `${this.httpUrl}/dynamic-settings/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many DynamicSettingss at the same time by ID.
        **/
        bulkReplace: (input: Array<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>> => {
            return apiCall<Array<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single DynamicSettings by ID.
        **/
        replace: (id: string, input: DynamicSettings, userToken: string): Promise<DynamicSettings> => {
            return apiCall<DynamicSettings>(
                `${this.httpUrl}/dynamic-settings/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many DynamicSettingss at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<DynamicSettings>, userToken: string): Promise<number> => {
            return apiCall<MassModification<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a DynamicSettings by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<DynamicSettings>, userToken: string): Promise<EntryChange<DynamicSettings>> => {
            return apiCall<Modification<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a DynamicSettings by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<DynamicSettings>, userToken: string): Promise<DynamicSettings> => {
            return apiCall<Modification<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching DynamicSettingss, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<DynamicSettings>, userToken: string): Promise<number> => {
            return apiCall<Condition<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a DynamicSettings by id.
        **/
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/dynamic-settings/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of DynamicSettingss matching the given condition.
        **/
        count: (input: Condition<DynamicSettings>, userToken: string): Promise<number> => {
            return apiCall<Condition<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of DynamicSettingss matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<DynamicSettings>, userToken: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of DynamicSettingss matching the given condition.
        **/
        aggregate: (input: AggregateQuery<DynamicSettings>, userToken: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of DynamicSettingss matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<DynamicSettings>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<DynamicSettings>>(
                `${this.httpUrl}/dynamic-settings/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly giveaway = {
        
        /**
        * Gets a default Giveaway that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<Giveaway> => {
            return apiCall<void>(
                `${this.httpUrl}/giveaways/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Giveaways that match the given query.
        **/
        query: (input: Query<Giveaway>, userToken?: string): Promise<Array<Giveaway>> => {
            return apiCall<Query<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Giveaways that match the given query.
        **/
        queryPartial: (input: QueryPartial<Giveaway>, userToken?: string): Promise<Array<DeepPartial<Giveaway>>> => {
            return apiCall<QueryPartial<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single Giveaway by ID.
        **/
        detail: (id: UUID, userToken?: string): Promise<Giveaway> => {
            return apiCall<void>(
                `${this.httpUrl}/giveaways/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Giveaways at the same time.
        **/
        insertBulk: (input: Array<Giveaway>, userToken?: string): Promise<Array<Giveaway>> => {
            return apiCall<Array<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new Giveaway
        **/
        insert: (input: Giveaway, userToken?: string): Promise<Giveaway> => {
            return apiCall<Giveaway>(
                `${this.httpUrl}/giveaways/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a Giveaway
        **/
        upsert: (id: UUID, input: Giveaway, userToken?: string): Promise<Giveaway> => {
            return apiCall<Giveaway>(
                `${this.httpUrl}/giveaways/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Giveaways at the same time by ID.
        **/
        bulkReplace: (input: Array<Giveaway>, userToken?: string): Promise<Array<Giveaway>> => {
            return apiCall<Array<Giveaway>>(
                `${this.httpUrl}/giveaways/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single Giveaway by ID.
        **/
        replace: (id: UUID, input: Giveaway, userToken?: string): Promise<Giveaway> => {
            return apiCall<Giveaway>(
                `${this.httpUrl}/giveaways/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Giveaways at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Giveaway>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Giveaway by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<Giveaway>, userToken?: string): Promise<EntryChange<Giveaway>> => {
            return apiCall<Modification<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Giveaway by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<Giveaway>, userToken?: string): Promise<Giveaway> => {
            return apiCall<Modification<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Giveaways, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Giveaway>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a Giveaway by id.
        **/
        delete: (id: UUID, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/giveaways/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Giveaways matching the given condition.
        **/
        count: (input: Condition<Giveaway>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Giveaways matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Giveaway>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Giveaways matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Giveaway>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Giveaways matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Giveaway>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<Giveaway>>(
                `${this.httpUrl}/giveaways/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly knownModel = {
        
        /**
        * Gets a default KnownModel that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<KnownModel> => {
            return apiCall<void>(
                `${this.httpUrl}/known-models/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of KnownModels that match the given query.
        **/
        query: (input: Query<KnownModel>, userToken?: string): Promise<Array<KnownModel>> => {
            return apiCall<Query<KnownModel>>(
                `${this.httpUrl}/known-models/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of KnownModels that match the given query.
        **/
        queryPartial: (input: QueryPartial<KnownModel>, userToken?: string): Promise<Array<DeepPartial<KnownModel>>> => {
            return apiCall<QueryPartial<KnownModel>>(
                `${this.httpUrl}/known-models/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single KnownModel by ID.
        **/
        detail: (id: string, userToken?: string): Promise<KnownModel> => {
            return apiCall<void>(
                `${this.httpUrl}/known-models/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple KnownModels at the same time.
        **/
        insertBulk: (input: Array<KnownModel>, userToken?: string): Promise<Array<KnownModel>> => {
            return apiCall<Array<KnownModel>>(
                `${this.httpUrl}/known-models/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new KnownModel
        **/
        insert: (input: KnownModel, userToken?: string): Promise<KnownModel> => {
            return apiCall<KnownModel>(
                `${this.httpUrl}/known-models/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a KnownModel
        **/
        upsert: (id: string, input: KnownModel, userToken?: string): Promise<KnownModel> => {
            return apiCall<KnownModel>(
                `${this.httpUrl}/known-models/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many KnownModels at the same time by ID.
        **/
        bulkReplace: (input: Array<KnownModel>, userToken?: string): Promise<Array<KnownModel>> => {
            return apiCall<Array<KnownModel>>(
                `${this.httpUrl}/known-models/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single KnownModel by ID.
        **/
        replace: (id: string, input: KnownModel, userToken?: string): Promise<KnownModel> => {
            return apiCall<KnownModel>(
                `${this.httpUrl}/known-models/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many KnownModels at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<KnownModel>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<KnownModel>>(
                `${this.httpUrl}/known-models/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a KnownModel by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<KnownModel>, userToken?: string): Promise<EntryChange<KnownModel>> => {
            return apiCall<Modification<KnownModel>>(
                `${this.httpUrl}/known-models/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a KnownModel by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<KnownModel>, userToken?: string): Promise<KnownModel> => {
            return apiCall<Modification<KnownModel>>(
                `${this.httpUrl}/known-models/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching KnownModels, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<KnownModel>, userToken?: string): Promise<number> => {
            return apiCall<Condition<KnownModel>>(
                `${this.httpUrl}/known-models/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a KnownModel by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/known-models/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of KnownModels matching the given condition.
        **/
        count: (input: Condition<KnownModel>, userToken?: string): Promise<number> => {
            return apiCall<Condition<KnownModel>>(
                `${this.httpUrl}/known-models/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of KnownModels matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<KnownModel>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<KnownModel>>(
                `${this.httpUrl}/known-models/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of KnownModels matching the given condition.
        **/
        aggregate: (input: AggregateQuery<KnownModel>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<KnownModel>>(
                `${this.httpUrl}/known-models/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of KnownModels matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<KnownModel>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<KnownModel>>(
                `${this.httpUrl}/known-models/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly legacyPayment = {
        
        /**
        * Gets a default Legacy-paymentRequest that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<void>(
                `${this.httpUrl}/legacy-payment/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Legacy-paymentRequests that match the given query.
        **/
        query: (input: Query<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>> => {
            return apiCall<Query<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Legacy-paymentRequests that match the given query.
        **/
        queryPartial: (input: QueryPartial<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<DeepPartial<ExternalAsyncTaskRequest>>> => {
            return apiCall<QueryPartial<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single Legacy-paymentRequest by ID.
        **/
        detail: (id: string, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<void>(
                `${this.httpUrl}/legacy-payment/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Legacy-paymentRequests at the same time.
        **/
        insertBulk: (input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>> => {
            return apiCall<Array<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new Legacy-paymentRequest
        **/
        insert: (input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<ExternalAsyncTaskRequest>(
                `${this.httpUrl}/legacy-payment/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a Legacy-paymentRequest
        **/
        upsert: (id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<ExternalAsyncTaskRequest>(
                `${this.httpUrl}/legacy-payment/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Legacy-paymentRequests at the same time by ID.
        **/
        bulkReplace: (input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>> => {
            return apiCall<Array<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single Legacy-paymentRequest by ID.
        **/
        replace: (id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<ExternalAsyncTaskRequest>(
                `${this.httpUrl}/legacy-payment/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Legacy-paymentRequests at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ExternalAsyncTaskRequest>, userToken: string): Promise<number> => {
            return apiCall<MassModification<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Legacy-paymentRequest by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<EntryChange<ExternalAsyncTaskRequest>> => {
            return apiCall<Modification<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Legacy-paymentRequest by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<Modification<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Legacy-paymentRequests, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number> => {
            return apiCall<Condition<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a Legacy-paymentRequest by id.
        **/
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/legacy-payment/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Legacy-paymentRequests matching the given condition.
        **/
        count: (input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number> => {
            return apiCall<Condition<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Legacy-paymentRequests matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Legacy-paymentRequests matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Legacy-paymentRequests matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/legacy-payment/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Manually recheck tasks
        **/
        manuallyRecheckTasks: (userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/legacy-payment/recheck`,
                undefined,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Manually recheck a task
        **/
        manuallyRecheckATask: (id: string, userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/legacy-payment/recheck/${id}`,
                undefined,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
    }
    readonly oEMAssembly = {
        
        /**
        * Gets a default OEMAssembly that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<OEMAssembly> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-assemblies/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of OEMAssemblys that match the given query.
        **/
        query: (input: Query<OEMAssembly>, userToken?: string): Promise<Array<OEMAssembly>> => {
            return apiCall<Query<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of OEMAssemblys that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMAssembly>, userToken?: string): Promise<Array<DeepPartial<OEMAssembly>>> => {
            return apiCall<QueryPartial<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single OEMAssembly by ID.
        **/
        detail: (id: string, userToken?: string): Promise<OEMAssembly> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-assemblies/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple OEMAssemblys at the same time.
        **/
        insertBulk: (input: Array<OEMAssembly>, userToken?: string): Promise<Array<OEMAssembly>> => {
            return apiCall<Array<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new OEMAssembly
        **/
        insert: (input: OEMAssembly, userToken?: string): Promise<OEMAssembly> => {
            return apiCall<OEMAssembly>(
                `${this.httpUrl}/oem-assemblies/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a OEMAssembly
        **/
        upsert: (id: string, input: OEMAssembly, userToken?: string): Promise<OEMAssembly> => {
            return apiCall<OEMAssembly>(
                `${this.httpUrl}/oem-assemblies/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMAssemblys at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMAssembly>, userToken?: string): Promise<Array<OEMAssembly>> => {
            return apiCall<Array<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single OEMAssembly by ID.
        **/
        replace: (id: string, input: OEMAssembly, userToken?: string): Promise<OEMAssembly> => {
            return apiCall<OEMAssembly>(
                `${this.httpUrl}/oem-assemblies/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMAssemblys at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMAssembly>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMAssembly by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<OEMAssembly>, userToken?: string): Promise<EntryChange<OEMAssembly>> => {
            return apiCall<Modification<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMAssembly by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<OEMAssembly>, userToken?: string): Promise<OEMAssembly> => {
            return apiCall<Modification<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching OEMAssemblys, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMAssembly>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a OEMAssembly by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-assemblies/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of OEMAssemblys matching the given condition.
        **/
        count: (input: Condition<OEMAssembly>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of OEMAssemblys matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMAssembly>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMAssemblys matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMAssembly>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMAssemblys matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMAssembly>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<OEMAssembly>>(
                `${this.httpUrl}/oem-assemblies/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly oEMMake = {
        
        /**
        * Gets a default OEMMake that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<OEMMake> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-make/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of OEMMakes that match the given query.
        **/
        query: (input: Query<OEMMake>, userToken?: string): Promise<Array<OEMMake>> => {
            return apiCall<Query<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of OEMMakes that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMMake>, userToken?: string): Promise<Array<DeepPartial<OEMMake>>> => {
            return apiCall<QueryPartial<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single OEMMake by ID.
        **/
        detail: (id: string, userToken?: string): Promise<OEMMake> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-make/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple OEMMakes at the same time.
        **/
        insertBulk: (input: Array<OEMMake>, userToken?: string): Promise<Array<OEMMake>> => {
            return apiCall<Array<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new OEMMake
        **/
        insert: (input: OEMMake, userToken?: string): Promise<OEMMake> => {
            return apiCall<OEMMake>(
                `${this.httpUrl}/oem-make/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a OEMMake
        **/
        upsert: (id: string, input: OEMMake, userToken?: string): Promise<OEMMake> => {
            return apiCall<OEMMake>(
                `${this.httpUrl}/oem-make/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMMakes at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMMake>, userToken?: string): Promise<Array<OEMMake>> => {
            return apiCall<Array<OEMMake>>(
                `${this.httpUrl}/oem-make/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single OEMMake by ID.
        **/
        replace: (id: string, input: OEMMake, userToken?: string): Promise<OEMMake> => {
            return apiCall<OEMMake>(
                `${this.httpUrl}/oem-make/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMMakes at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMMake>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMMake by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<OEMMake>, userToken?: string): Promise<EntryChange<OEMMake>> => {
            return apiCall<Modification<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMMake by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<OEMMake>, userToken?: string): Promise<OEMMake> => {
            return apiCall<Modification<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching OEMMakes, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMMake>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a OEMMake by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-make/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of OEMMakes matching the given condition.
        **/
        count: (input: Condition<OEMMake>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of OEMMakes matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMMake>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMMakes matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMMake>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMMakes matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMMake>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<OEMMake>>(
                `${this.httpUrl}/oem-make/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly oEMModel = {
        
        /**
        * Gets a default OEMModel that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<OEMModel> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-model/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of OEMModels that match the given query.
        **/
        query: (input: Query<OEMModel>, userToken?: string): Promise<Array<OEMModel>> => {
            return apiCall<Query<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of OEMModels that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMModel>, userToken?: string): Promise<Array<DeepPartial<OEMModel>>> => {
            return apiCall<QueryPartial<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single OEMModel by ID.
        **/
        detail: (id: string, userToken?: string): Promise<OEMModel> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-model/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple OEMModels at the same time.
        **/
        insertBulk: (input: Array<OEMModel>, userToken?: string): Promise<Array<OEMModel>> => {
            return apiCall<Array<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new OEMModel
        **/
        insert: (input: OEMModel, userToken?: string): Promise<OEMModel> => {
            return apiCall<OEMModel>(
                `${this.httpUrl}/oem-model/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a OEMModel
        **/
        upsert: (id: string, input: OEMModel, userToken?: string): Promise<OEMModel> => {
            return apiCall<OEMModel>(
                `${this.httpUrl}/oem-model/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMModels at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMModel>, userToken?: string): Promise<Array<OEMModel>> => {
            return apiCall<Array<OEMModel>>(
                `${this.httpUrl}/oem-model/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single OEMModel by ID.
        **/
        replace: (id: string, input: OEMModel, userToken?: string): Promise<OEMModel> => {
            return apiCall<OEMModel>(
                `${this.httpUrl}/oem-model/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMModels at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMModel>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMModel by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<OEMModel>, userToken?: string): Promise<EntryChange<OEMModel>> => {
            return apiCall<Modification<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMModel by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<OEMModel>, userToken?: string): Promise<OEMModel> => {
            return apiCall<Modification<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching OEMModels, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMModel>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a OEMModel by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-model/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of OEMModels matching the given condition.
        **/
        count: (input: Condition<OEMModel>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of OEMModels matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMModel>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMModels matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMModel>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMModels matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMModel>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<OEMModel>>(
                `${this.httpUrl}/oem-model/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly oEMVehicle = {
        
        /**
        * Gets a default OEMVehicle that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<OEMVehicle> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-vehicle/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of OEMVehicles that match the given query.
        **/
        query: (input: Query<OEMVehicle>, userToken?: string): Promise<Array<OEMVehicle>> => {
            return apiCall<Query<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of OEMVehicles that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMVehicle>, userToken?: string): Promise<Array<DeepPartial<OEMVehicle>>> => {
            return apiCall<QueryPartial<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single OEMVehicle by ID.
        **/
        detail: (id: UUID, userToken?: string): Promise<OEMVehicle> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-vehicle/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple OEMVehicles at the same time.
        **/
        insertBulk: (input: Array<OEMVehicle>, userToken?: string): Promise<Array<OEMVehicle>> => {
            return apiCall<Array<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new OEMVehicle
        **/
        insert: (input: OEMVehicle, userToken?: string): Promise<OEMVehicle> => {
            return apiCall<OEMVehicle>(
                `${this.httpUrl}/oem-vehicle/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a OEMVehicle
        **/
        upsert: (id: UUID, input: OEMVehicle, userToken?: string): Promise<OEMVehicle> => {
            return apiCall<OEMVehicle>(
                `${this.httpUrl}/oem-vehicle/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMVehicles at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMVehicle>, userToken?: string): Promise<Array<OEMVehicle>> => {
            return apiCall<Array<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single OEMVehicle by ID.
        **/
        replace: (id: UUID, input: OEMVehicle, userToken?: string): Promise<OEMVehicle> => {
            return apiCall<OEMVehicle>(
                `${this.httpUrl}/oem-vehicle/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMVehicles at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMVehicle>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMVehicle by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<OEMVehicle>, userToken?: string): Promise<EntryChange<OEMVehicle>> => {
            return apiCall<Modification<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMVehicle by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<OEMVehicle>, userToken?: string): Promise<OEMVehicle> => {
            return apiCall<Modification<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching OEMVehicles, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMVehicle>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a OEMVehicle by id.
        **/
        delete: (id: UUID, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-vehicle/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of OEMVehicles matching the given condition.
        **/
        count: (input: Condition<OEMVehicle>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of OEMVehicles matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMVehicle>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMVehicles matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMVehicle>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMVehicles matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMVehicle>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<OEMVehicle>>(
                `${this.httpUrl}/oem-vehicle/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly oEMYear = {
        
        /**
        * Gets a default OEMYear that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<OEMYear> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-year/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of OEMYears that match the given query.
        **/
        query: (input: Query<OEMYear>, userToken?: string): Promise<Array<OEMYear>> => {
            return apiCall<Query<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of OEMYears that match the given query.
        **/
        queryPartial: (input: QueryPartial<OEMYear>, userToken?: string): Promise<Array<DeepPartial<OEMYear>>> => {
            return apiCall<QueryPartial<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single OEMYear by ID.
        **/
        detail: (id: UUID, userToken?: string): Promise<OEMYear> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-year/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple OEMYears at the same time.
        **/
        insertBulk: (input: Array<OEMYear>, userToken?: string): Promise<Array<OEMYear>> => {
            return apiCall<Array<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new OEMYear
        **/
        insert: (input: OEMYear, userToken?: string): Promise<OEMYear> => {
            return apiCall<OEMYear>(
                `${this.httpUrl}/oem-year/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a OEMYear
        **/
        upsert: (id: UUID, input: OEMYear, userToken?: string): Promise<OEMYear> => {
            return apiCall<OEMYear>(
                `${this.httpUrl}/oem-year/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMYears at the same time by ID.
        **/
        bulkReplace: (input: Array<OEMYear>, userToken?: string): Promise<Array<OEMYear>> => {
            return apiCall<Array<OEMYear>>(
                `${this.httpUrl}/oem-year/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single OEMYear by ID.
        **/
        replace: (id: UUID, input: OEMYear, userToken?: string): Promise<OEMYear> => {
            return apiCall<OEMYear>(
                `${this.httpUrl}/oem-year/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many OEMYears at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<OEMYear>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMYear by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<OEMYear>, userToken?: string): Promise<EntryChange<OEMYear>> => {
            return apiCall<Modification<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a OEMYear by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<OEMYear>, userToken?: string): Promise<OEMYear> => {
            return apiCall<Modification<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching OEMYears, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<OEMYear>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a OEMYear by id.
        **/
        delete: (id: UUID, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/oem-year/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of OEMYears matching the given condition.
        **/
        count: (input: Condition<OEMYear>, userToken?: string): Promise<number> => {
            return apiCall<Condition<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of OEMYears matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<OEMYear>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMYears matching the given condition.
        **/
        aggregate: (input: AggregateQuery<OEMYear>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of OEMYears matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<OEMYear>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<OEMYear>>(
                `${this.httpUrl}/oem-year/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly order = {
        
        /**
        * Gets a default Order that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<Order> => {
            return apiCall<void>(
                `${this.httpUrl}/orders/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Orders that match the given query.
        **/
        query: (input: Query<Order>, userToken?: string): Promise<Array<Order>> => {
            return apiCall<Query<Order>>(
                `${this.httpUrl}/orders/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Orders that match the given query.
        **/
        queryPartial: (input: QueryPartial<Order>, userToken?: string): Promise<Array<DeepPartial<Order>>> => {
            return apiCall<QueryPartial<Order>>(
                `${this.httpUrl}/orders/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single Order by ID.
        **/
        detail: (id: UUID, userToken?: string): Promise<Order> => {
            return apiCall<void>(
                `${this.httpUrl}/orders/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Orders at the same time.
        **/
        insertBulk: (input: Array<Order>, userToken?: string): Promise<Array<Order>> => {
            return apiCall<Array<Order>>(
                `${this.httpUrl}/orders/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new Order
        **/
        insert: (input: Order, userToken?: string): Promise<Order> => {
            return apiCall<Order>(
                `${this.httpUrl}/orders/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a Order
        **/
        upsert: (id: UUID, input: Order, userToken?: string): Promise<Order> => {
            return apiCall<Order>(
                `${this.httpUrl}/orders/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Orders at the same time by ID.
        **/
        bulkReplace: (input: Array<Order>, userToken?: string): Promise<Array<Order>> => {
            return apiCall<Array<Order>>(
                `${this.httpUrl}/orders/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single Order by ID.
        **/
        replace: (id: UUID, input: Order, userToken?: string): Promise<Order> => {
            return apiCall<Order>(
                `${this.httpUrl}/orders/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Orders at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Order>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<Order>>(
                `${this.httpUrl}/orders/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Order by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<Order>, userToken?: string): Promise<EntryChange<Order>> => {
            return apiCall<Modification<Order>>(
                `${this.httpUrl}/orders/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Order by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<Order>, userToken?: string): Promise<Order> => {
            return apiCall<Modification<Order>>(
                `${this.httpUrl}/orders/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Orders, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Order>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Order>>(
                `${this.httpUrl}/orders/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a Order by id.
        **/
        delete: (id: UUID, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/orders/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Orders matching the given condition.
        **/
        count: (input: Condition<Order>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Order>>(
                `${this.httpUrl}/orders/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Orders matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Order>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<Order>>(
                `${this.httpUrl}/orders/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Orders matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Order>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<Order>>(
                `${this.httpUrl}/orders/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Orders matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Order>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<Order>>(
                `${this.httpUrl}/orders/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly payment = {
        
        /**
        * Gets a default PaymentRequest that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<void>(
                `${this.httpUrl}/payment/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of PaymentRequests that match the given query.
        **/
        query: (input: Query<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>> => {
            return apiCall<Query<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of PaymentRequests that match the given query.
        **/
        queryPartial: (input: QueryPartial<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<DeepPartial<ExternalAsyncTaskRequest>>> => {
            return apiCall<QueryPartial<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single PaymentRequest by ID.
        **/
        detail: (id: string, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<void>(
                `${this.httpUrl}/payment/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple PaymentRequests at the same time.
        **/
        insertBulk: (input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>> => {
            return apiCall<Array<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new PaymentRequest
        **/
        insert: (input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<ExternalAsyncTaskRequest>(
                `${this.httpUrl}/payment/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a PaymentRequest
        **/
        upsert: (id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<ExternalAsyncTaskRequest>(
                `${this.httpUrl}/payment/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many PaymentRequests at the same time by ID.
        **/
        bulkReplace: (input: Array<ExternalAsyncTaskRequest>, userToken: string): Promise<Array<ExternalAsyncTaskRequest>> => {
            return apiCall<Array<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single PaymentRequest by ID.
        **/
        replace: (id: string, input: ExternalAsyncTaskRequest, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<ExternalAsyncTaskRequest>(
                `${this.httpUrl}/payment/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many PaymentRequests at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ExternalAsyncTaskRequest>, userToken: string): Promise<number> => {
            return apiCall<MassModification<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a PaymentRequest by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<EntryChange<ExternalAsyncTaskRequest>> => {
            return apiCall<Modification<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a PaymentRequest by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ExternalAsyncTaskRequest>, userToken: string): Promise<ExternalAsyncTaskRequest> => {
            return apiCall<Modification<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching PaymentRequests, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number> => {
            return apiCall<Condition<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a PaymentRequest by id.
        **/
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/payment/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of PaymentRequests matching the given condition.
        **/
        count: (input: Condition<ExternalAsyncTaskRequest>, userToken: string): Promise<number> => {
            return apiCall<Condition<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of PaymentRequests matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of PaymentRequests matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of PaymentRequests matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ExternalAsyncTaskRequest>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<ExternalAsyncTaskRequest>>(
                `${this.httpUrl}/payment/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Manually recheck tasks
        **/
        manuallyRecheckTasks: (userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/payment/recheck`,
                undefined,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Manually recheck a task
        **/
        manuallyRecheckATask: (id: string, userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/payment/recheck/${id}`,
                undefined,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
    }
    readonly product = {
        
        /**
        * Gets a default Product that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<Product> => {
            return apiCall<void>(
                `${this.httpUrl}/products/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Products that match the given query.
        **/
        query: (input: Query<Product>, userToken?: string): Promise<Array<Product>> => {
            return apiCall<Query<Product>>(
                `${this.httpUrl}/products/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Products that match the given query.
        **/
        queryPartial: (input: QueryPartial<Product>, userToken?: string): Promise<Array<DeepPartial<Product>>> => {
            return apiCall<QueryPartial<Product>>(
                `${this.httpUrl}/products/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single Product by ID.
        **/
        detail: (id: string, userToken?: string): Promise<Product> => {
            return apiCall<void>(
                `${this.httpUrl}/products/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Products at the same time.
        **/
        insertBulk: (input: Array<Product>, userToken?: string): Promise<Array<Product>> => {
            return apiCall<Array<Product>>(
                `${this.httpUrl}/products/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new Product
        **/
        insert: (input: Product, userToken?: string): Promise<Product> => {
            return apiCall<Product>(
                `${this.httpUrl}/products/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a Product
        **/
        upsert: (id: string, input: Product, userToken?: string): Promise<Product> => {
            return apiCall<Product>(
                `${this.httpUrl}/products/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Products at the same time by ID.
        **/
        bulkReplace: (input: Array<Product>, userToken?: string): Promise<Array<Product>> => {
            return apiCall<Array<Product>>(
                `${this.httpUrl}/products/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single Product by ID.
        **/
        replace: (id: string, input: Product, userToken?: string): Promise<Product> => {
            return apiCall<Product>(
                `${this.httpUrl}/products/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Products at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Product>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<Product>>(
                `${this.httpUrl}/products/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Product by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<Product>, userToken?: string): Promise<EntryChange<Product>> => {
            return apiCall<Modification<Product>>(
                `${this.httpUrl}/products/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Product by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<Product>, userToken?: string): Promise<Product> => {
            return apiCall<Modification<Product>>(
                `${this.httpUrl}/products/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Products, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Product>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Product>>(
                `${this.httpUrl}/products/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a Product by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/products/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Products matching the given condition.
        **/
        count: (input: Condition<Product>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Product>>(
                `${this.httpUrl}/products/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Products matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Product>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<Product>>(
                `${this.httpUrl}/products/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Products matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Product>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<Product>>(
                `${this.httpUrl}/products/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Products matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Product>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<Product>>(
                `${this.httpUrl}/products/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Returns a minimized model for page building purposes
        **/
        minimal: (input: Query<Product>, userToken?: string): Promise<Array<ProductMinimized>> => {
            return apiCall<Query<Product>>(
                `${this.httpUrl}/products/rest/minimal`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly productTag = {
        
        /**
        * Gets a default ProductTag that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<ProductTag> => {
            return apiCall<void>(
                `${this.httpUrl}/tags/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of ProductTags that match the given query.
        **/
        query: (input: Query<ProductTag>, userToken?: string): Promise<Array<ProductTag>> => {
            return apiCall<Query<ProductTag>>(
                `${this.httpUrl}/tags/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of ProductTags that match the given query.
        **/
        queryPartial: (input: QueryPartial<ProductTag>, userToken?: string): Promise<Array<DeepPartial<ProductTag>>> => {
            return apiCall<QueryPartial<ProductTag>>(
                `${this.httpUrl}/tags/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single ProductTag by ID.
        **/
        detail: (id: string, userToken?: string): Promise<ProductTag> => {
            return apiCall<void>(
                `${this.httpUrl}/tags/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple ProductTags at the same time.
        **/
        insertBulk: (input: Array<ProductTag>, userToken?: string): Promise<Array<ProductTag>> => {
            return apiCall<Array<ProductTag>>(
                `${this.httpUrl}/tags/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new ProductTag
        **/
        insert: (input: ProductTag, userToken?: string): Promise<ProductTag> => {
            return apiCall<ProductTag>(
                `${this.httpUrl}/tags/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a ProductTag
        **/
        upsert: (id: string, input: ProductTag, userToken?: string): Promise<ProductTag> => {
            return apiCall<ProductTag>(
                `${this.httpUrl}/tags/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ProductTags at the same time by ID.
        **/
        bulkReplace: (input: Array<ProductTag>, userToken?: string): Promise<Array<ProductTag>> => {
            return apiCall<Array<ProductTag>>(
                `${this.httpUrl}/tags/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single ProductTag by ID.
        **/
        replace: (id: string, input: ProductTag, userToken?: string): Promise<ProductTag> => {
            return apiCall<ProductTag>(
                `${this.httpUrl}/tags/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ProductTags at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ProductTag>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<ProductTag>>(
                `${this.httpUrl}/tags/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ProductTag by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<ProductTag>, userToken?: string): Promise<EntryChange<ProductTag>> => {
            return apiCall<Modification<ProductTag>>(
                `${this.httpUrl}/tags/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ProductTag by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<ProductTag>, userToken?: string): Promise<ProductTag> => {
            return apiCall<Modification<ProductTag>>(
                `${this.httpUrl}/tags/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching ProductTags, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ProductTag>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ProductTag>>(
                `${this.httpUrl}/tags/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a ProductTag by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/tags/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of ProductTags matching the given condition.
        **/
        count: (input: Condition<ProductTag>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ProductTag>>(
                `${this.httpUrl}/tags/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of ProductTags matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ProductTag>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<ProductTag>>(
                `${this.httpUrl}/tags/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ProductTags matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ProductTag>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<ProductTag>>(
                `${this.httpUrl}/tags/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ProductTags matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ProductTag>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<ProductTag>>(
                `${this.httpUrl}/tags/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly productVariant = {
        
        /**
        * Gets a default ProductVariant that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<ProductVariant> => {
            return apiCall<void>(
                `${this.httpUrl}/product-variants/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of ProductVariants that match the given query.
        **/
        query: (input: Query<ProductVariant>, userToken?: string): Promise<Array<ProductVariant>> => {
            return apiCall<Query<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of ProductVariants that match the given query.
        **/
        queryPartial: (input: QueryPartial<ProductVariant>, userToken?: string): Promise<Array<DeepPartial<ProductVariant>>> => {
            return apiCall<QueryPartial<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single ProductVariant by ID.
        **/
        detail: (id: UUID, userToken?: string): Promise<ProductVariant> => {
            return apiCall<void>(
                `${this.httpUrl}/product-variants/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple ProductVariants at the same time.
        **/
        insertBulk: (input: Array<ProductVariant>, userToken?: string): Promise<Array<ProductVariant>> => {
            return apiCall<Array<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new ProductVariant
        **/
        insert: (input: ProductVariant, userToken?: string): Promise<ProductVariant> => {
            return apiCall<ProductVariant>(
                `${this.httpUrl}/product-variants/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a ProductVariant
        **/
        upsert: (id: UUID, input: ProductVariant, userToken?: string): Promise<ProductVariant> => {
            return apiCall<ProductVariant>(
                `${this.httpUrl}/product-variants/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ProductVariants at the same time by ID.
        **/
        bulkReplace: (input: Array<ProductVariant>, userToken?: string): Promise<Array<ProductVariant>> => {
            return apiCall<Array<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single ProductVariant by ID.
        **/
        replace: (id: UUID, input: ProductVariant, userToken?: string): Promise<ProductVariant> => {
            return apiCall<ProductVariant>(
                `${this.httpUrl}/product-variants/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ProductVariants at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ProductVariant>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ProductVariant by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<ProductVariant>, userToken?: string): Promise<EntryChange<ProductVariant>> => {
            return apiCall<Modification<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ProductVariant by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<ProductVariant>, userToken?: string): Promise<ProductVariant> => {
            return apiCall<Modification<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching ProductVariants, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ProductVariant>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a ProductVariant by id.
        **/
        delete: (id: UUID, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/product-variants/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of ProductVariants matching the given condition.
        **/
        count: (input: Condition<ProductVariant>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of ProductVariants matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ProductVariant>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ProductVariants matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ProductVariant>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ProductVariants matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ProductVariant>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<ProductVariant>>(
                `${this.httpUrl}/product-variants/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly review = {
        
        /**
        * Gets a default Review that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<Review> => {
            return apiCall<void>(
                `${this.httpUrl}/reviews/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Reviews that match the given query.
        **/
        query: (input: Query<Review>, userToken?: string): Promise<Array<Review>> => {
            return apiCall<Query<Review>>(
                `${this.httpUrl}/reviews/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Reviews that match the given query.
        **/
        queryPartial: (input: QueryPartial<Review>, userToken?: string): Promise<Array<DeepPartial<Review>>> => {
            return apiCall<QueryPartial<Review>>(
                `${this.httpUrl}/reviews/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single Review by ID.
        **/
        detail: (id: UUID, userToken?: string): Promise<Review> => {
            return apiCall<void>(
                `${this.httpUrl}/reviews/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Reviews at the same time.
        **/
        insertBulk: (input: Array<Review>, userToken?: string): Promise<Array<Review>> => {
            return apiCall<Array<Review>>(
                `${this.httpUrl}/reviews/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new Review
        **/
        insert: (input: Review, userToken?: string): Promise<Review> => {
            return apiCall<Review>(
                `${this.httpUrl}/reviews/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a Review
        **/
        upsert: (id: UUID, input: Review, userToken?: string): Promise<Review> => {
            return apiCall<Review>(
                `${this.httpUrl}/reviews/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Reviews at the same time by ID.
        **/
        bulkReplace: (input: Array<Review>, userToken?: string): Promise<Array<Review>> => {
            return apiCall<Array<Review>>(
                `${this.httpUrl}/reviews/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single Review by ID.
        **/
        replace: (id: UUID, input: Review, userToken?: string): Promise<Review> => {
            return apiCall<Review>(
                `${this.httpUrl}/reviews/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Reviews at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Review>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<Review>>(
                `${this.httpUrl}/reviews/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Review by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<Review>, userToken?: string): Promise<EntryChange<Review>> => {
            return apiCall<Modification<Review>>(
                `${this.httpUrl}/reviews/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Review by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<Review>, userToken?: string): Promise<Review> => {
            return apiCall<Modification<Review>>(
                `${this.httpUrl}/reviews/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Reviews, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Review>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Review>>(
                `${this.httpUrl}/reviews/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a Review by id.
        **/
        delete: (id: UUID, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/reviews/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Reviews matching the given condition.
        **/
        count: (input: Condition<Review>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Review>>(
                `${this.httpUrl}/reviews/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Reviews matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Review>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<Review>>(
                `${this.httpUrl}/reviews/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Reviews matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Review>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<Review>>(
                `${this.httpUrl}/reviews/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Reviews matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Review>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<Review>>(
                `${this.httpUrl}/reviews/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly sale = {
        
        /**
        * Gets a default Sale that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<Sale> => {
            return apiCall<void>(
                `${this.httpUrl}/sales/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Sales that match the given query.
        **/
        query: (input: Query<Sale>, userToken?: string): Promise<Array<Sale>> => {
            return apiCall<Query<Sale>>(
                `${this.httpUrl}/sales/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Sales that match the given query.
        **/
        queryPartial: (input: QueryPartial<Sale>, userToken?: string): Promise<Array<DeepPartial<Sale>>> => {
            return apiCall<QueryPartial<Sale>>(
                `${this.httpUrl}/sales/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single Sale by ID.
        **/
        detail: (id: string, userToken?: string): Promise<Sale> => {
            return apiCall<void>(
                `${this.httpUrl}/sales/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Sales at the same time.
        **/
        insertBulk: (input: Array<Sale>, userToken?: string): Promise<Array<Sale>> => {
            return apiCall<Array<Sale>>(
                `${this.httpUrl}/sales/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new Sale
        **/
        insert: (input: Sale, userToken?: string): Promise<Sale> => {
            return apiCall<Sale>(
                `${this.httpUrl}/sales/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a Sale
        **/
        upsert: (id: string, input: Sale, userToken?: string): Promise<Sale> => {
            return apiCall<Sale>(
                `${this.httpUrl}/sales/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Sales at the same time by ID.
        **/
        bulkReplace: (input: Array<Sale>, userToken?: string): Promise<Array<Sale>> => {
            return apiCall<Array<Sale>>(
                `${this.httpUrl}/sales/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single Sale by ID.
        **/
        replace: (id: string, input: Sale, userToken?: string): Promise<Sale> => {
            return apiCall<Sale>(
                `${this.httpUrl}/sales/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Sales at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<Sale>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<Sale>>(
                `${this.httpUrl}/sales/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Sale by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<Sale>, userToken?: string): Promise<EntryChange<Sale>> => {
            return apiCall<Modification<Sale>>(
                `${this.httpUrl}/sales/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a Sale by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<Sale>, userToken?: string): Promise<Sale> => {
            return apiCall<Modification<Sale>>(
                `${this.httpUrl}/sales/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Sales, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<Sale>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Sale>>(
                `${this.httpUrl}/sales/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a Sale by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/sales/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Sales matching the given condition.
        **/
        count: (input: Condition<Sale>, userToken?: string): Promise<number> => {
            return apiCall<Condition<Sale>>(
                `${this.httpUrl}/sales/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Sales matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<Sale>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<Sale>>(
                `${this.httpUrl}/sales/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Sales matching the given condition.
        **/
        aggregate: (input: AggregateQuery<Sale>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<Sale>>(
                `${this.httpUrl}/sales/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Sales matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<Sale>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<Sale>>(
                `${this.httpUrl}/sales/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly shopTalk = {
        
        /**
        * Gets a default ShopTalk that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<ShopTalk> => {
            return apiCall<void>(
                `${this.httpUrl}/shoptalk/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of ShopTalks that match the given query.
        **/
        query: (input: Query<ShopTalk>, userToken?: string): Promise<Array<ShopTalk>> => {
            return apiCall<Query<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of ShopTalks that match the given query.
        **/
        queryPartial: (input: QueryPartial<ShopTalk>, userToken?: string): Promise<Array<DeepPartial<ShopTalk>>> => {
            return apiCall<QueryPartial<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single ShopTalk by ID.
        **/
        detail: (id: UUID, userToken?: string): Promise<ShopTalk> => {
            return apiCall<void>(
                `${this.httpUrl}/shoptalk/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple ShopTalks at the same time.
        **/
        insertBulk: (input: Array<ShopTalk>, userToken?: string): Promise<Array<ShopTalk>> => {
            return apiCall<Array<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new ShopTalk
        **/
        insert: (input: ShopTalk, userToken?: string): Promise<ShopTalk> => {
            return apiCall<ShopTalk>(
                `${this.httpUrl}/shoptalk/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a ShopTalk
        **/
        upsert: (id: UUID, input: ShopTalk, userToken?: string): Promise<ShopTalk> => {
            return apiCall<ShopTalk>(
                `${this.httpUrl}/shoptalk/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ShopTalks at the same time by ID.
        **/
        bulkReplace: (input: Array<ShopTalk>, userToken?: string): Promise<Array<ShopTalk>> => {
            return apiCall<Array<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single ShopTalk by ID.
        **/
        replace: (id: UUID, input: ShopTalk, userToken?: string): Promise<ShopTalk> => {
            return apiCall<ShopTalk>(
                `${this.httpUrl}/shoptalk/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many ShopTalks at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<ShopTalk>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ShopTalk by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<ShopTalk>, userToken?: string): Promise<EntryChange<ShopTalk>> => {
            return apiCall<Modification<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a ShopTalk by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<ShopTalk>, userToken?: string): Promise<ShopTalk> => {
            return apiCall<Modification<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching ShopTalks, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<ShopTalk>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a ShopTalk by id.
        **/
        delete: (id: UUID, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/shoptalk/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of ShopTalks matching the given condition.
        **/
        count: (input: Condition<ShopTalk>, userToken?: string): Promise<number> => {
            return apiCall<Condition<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of ShopTalks matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<ShopTalk>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ShopTalks matching the given condition.
        **/
        aggregate: (input: AggregateQuery<ShopTalk>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of ShopTalks matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<ShopTalk>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<ShopTalk>>(
                `${this.httpUrl}/shoptalk/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly syncProgress = {
        
        /**
        * Gets a default SyncProgress that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken?: string): Promise<SyncProgress> => {
            return apiCall<void>(
                `${this.httpUrl}/sync-progress/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of SyncProgresss that match the given query.
        **/
        query: (input: Query<SyncProgress>, userToken?: string): Promise<Array<SyncProgress>> => {
            return apiCall<Query<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of SyncProgresss that match the given query.
        **/
        queryPartial: (input: QueryPartial<SyncProgress>, userToken?: string): Promise<Array<DeepPartial<SyncProgress>>> => {
            return apiCall<QueryPartial<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single SyncProgress by ID.
        **/
        detail: (id: string, userToken?: string): Promise<SyncProgress> => {
            return apiCall<void>(
                `${this.httpUrl}/sync-progress/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple SyncProgresss at the same time.
        **/
        insertBulk: (input: Array<SyncProgress>, userToken?: string): Promise<Array<SyncProgress>> => {
            return apiCall<Array<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new SyncProgress
        **/
        insert: (input: SyncProgress, userToken?: string): Promise<SyncProgress> => {
            return apiCall<SyncProgress>(
                `${this.httpUrl}/sync-progress/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a SyncProgress
        **/
        upsert: (id: string, input: SyncProgress, userToken?: string): Promise<SyncProgress> => {
            return apiCall<SyncProgress>(
                `${this.httpUrl}/sync-progress/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many SyncProgresss at the same time by ID.
        **/
        bulkReplace: (input: Array<SyncProgress>, userToken?: string): Promise<Array<SyncProgress>> => {
            return apiCall<Array<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single SyncProgress by ID.
        **/
        replace: (id: string, input: SyncProgress, userToken?: string): Promise<SyncProgress> => {
            return apiCall<SyncProgress>(
                `${this.httpUrl}/sync-progress/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many SyncProgresss at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<SyncProgress>, userToken?: string): Promise<number> => {
            return apiCall<MassModification<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a SyncProgress by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: string, input: Modification<SyncProgress>, userToken?: string): Promise<EntryChange<SyncProgress>> => {
            return apiCall<Modification<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a SyncProgress by ID, returning the new value.
        **/
        modify: (id: string, input: Modification<SyncProgress>, userToken?: string): Promise<SyncProgress> => {
            return apiCall<Modification<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching SyncProgresss, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<SyncProgress>, userToken?: string): Promise<number> => {
            return apiCall<Condition<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a SyncProgress by id.
        **/
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/sync-progress/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of SyncProgresss matching the given condition.
        **/
        count: (input: Condition<SyncProgress>, userToken?: string): Promise<number> => {
            return apiCall<Condition<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of SyncProgresss matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<SyncProgress>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of SyncProgresss matching the given condition.
        **/
        aggregate: (input: AggregateQuery<SyncProgress>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of SyncProgresss matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<SyncProgress>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<SyncProgress>>(
                `${this.httpUrl}/sync-progress/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
    readonly user = {
        
        /**
        * Gets a default User that would be useful to start creating a full one to insert.  Primarily used for administrative interfaces.
        **/
        default: (userToken: string): Promise<User> => {
            return apiCall<void>(
                `${this.httpUrl}/users/rest/_default_`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a list of Users that match the given query.
        **/
        query: (input: Query<User>, userToken: string): Promise<Array<User>> => {
            return apiCall<Query<User>>(
                `${this.httpUrl}/users/rest/query`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets parts of Users that match the given query.
        **/
        queryPartial: (input: QueryPartial<User>, userToken: string): Promise<Array<DeepPartial<User>>> => {
            return apiCall<QueryPartial<User>>(
                `${this.httpUrl}/users/rest/query-partial`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets a single User by ID.
        **/
        detail: (id: UUID, userToken: string): Promise<User> => {
            return apiCall<void>(
                `${this.httpUrl}/users/rest/${id}`,
                undefined,
                {
                    method: "GET",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates multiple Users at the same time.
        **/
        insertBulk: (input: Array<User>, userToken: string): Promise<Array<User>> => {
            return apiCall<Array<User>>(
                `${this.httpUrl}/users/rest/bulk`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates a new User
        **/
        insert: (input: User, userToken: string): Promise<User> => {
            return apiCall<User>(
                `${this.httpUrl}/users/rest`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Creates or updates a User
        **/
        upsert: (id: UUID, input: User, userToken: string): Promise<User> => {
            return apiCall<User>(
                `${this.httpUrl}/users/rest/${id}`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Users at the same time by ID.
        **/
        bulkReplace: (input: Array<User>, userToken: string): Promise<Array<User>> => {
            return apiCall<Array<User>>(
                `${this.httpUrl}/users/rest`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Replaces a single User by ID.
        **/
        replace: (id: UUID, input: User, userToken: string): Promise<User> => {
            return apiCall<User>(
                `${this.httpUrl}/users/rest/${id}`,
                input,
                {
                    method: "PUT",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies many Users at the same time.  Returns the number of changed items.
        **/
        bulkModify: (input: MassModification<User>, userToken: string): Promise<number> => {
            return apiCall<MassModification<User>>(
                `${this.httpUrl}/users/rest/bulk`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a User by ID, returning both the previous value and new value.
        **/
        modifyWithDiff: (id: UUID, input: Modification<User>, userToken: string): Promise<EntryChange<User>> => {
            return apiCall<Modification<User>>(
                `${this.httpUrl}/users/rest/${id}/delta`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Modifies a User by ID, returning the new value.
        **/
        modify: (id: UUID, input: Modification<User>, userToken: string): Promise<User> => {
            return apiCall<Modification<User>>(
                `${this.httpUrl}/users/rest/${id}`,
                input,
                {
                    method: "PATCH",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes all matching Users, returning the number of deleted items.
        **/
        bulkDelete: (input: Condition<User>, userToken: string): Promise<number> => {
            return apiCall<Condition<User>>(
                `${this.httpUrl}/users/rest/bulk-delete`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Deletes a User by id.
        **/
        delete: (id: UUID, userToken: string): Promise<void> => {
            return apiCall<void>(
                `${this.httpUrl}/users/rest/${id}`,
                undefined,
                {
                    method: "DELETE",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => undefined)
        },
        
        /**
        * Gets the total number of Users matching the given condition.
        **/
        count: (input: Condition<User>, userToken: string): Promise<number> => {
            return apiCall<Condition<User>>(
                `${this.httpUrl}/users/rest/count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Gets the total number of Users matching the given condition divided by group.
        **/
        groupCount: (input: GroupCountQuery<User>, userToken: string): Promise<Record<string, number>> => {
            return apiCall<GroupCountQuery<User>>(
                `${this.httpUrl}/users/rest/group-count`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Users matching the given condition.
        **/
        aggregate: (input: AggregateQuery<User>, userToken: string): Promise<number | null | undefined> => {
            return apiCall<AggregateQuery<User>>(
                `${this.httpUrl}/users/rest/aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
        
        /**
        * Aggregates a property of Users matching the given condition divided by group.
        **/
        groupAggregate: (input: GroupAggregateQuery<User>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall<GroupAggregateQuery<User>>(
                `${this.httpUrl}/users/rest/group-aggregate`,
                input,
                {
                    method: "POST",
                    headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
                }, 
                undefined,
                this.responseInterceptors, 
            ).then(x => x.json())
        },
    }
}

